@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

* {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

body {
  background-size: 100%;
  font-weight: 400;
  /* font-family: "givonic"; */
  font-family: "givonic";
}

.gradientBg {
  border-bottom-left-radius: 70px;
  border-bottom-right-radius: 70px;
  /* background: #4568dc; */
  background: #fafafa !important;
  /* background: #f5f5f5 !important; */
  /* background: -webkit-linear-gradient(to right, #f5f5f569, #f5f5f5); */
  /* background: linear-gradient(to right, #f5f5f569, #f5f5f5); */
  /* background: -webkit-linear-gradient(to right, #b06ab3, #4568dc); */
  /* background: linear-gradient(to right, #b06ab3, #4568dc); */
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: "givonic";
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
}

.fa-fa-font-white {
  color: rgb(255, 0, 0);
  font-size: 18px;
  cursor: pointer;
}

.fa-fa-font-red {
  color: rgb(255, 0, 0);
  font-size: 18px;
  cursor: pointer;
}

.fa-fa-font-white-text {
  color: #000;
  font-size: 14px;
}

.card-img-overlay-custome {
  position: absolute;
  /* top: -107px; */
  right: 30px;
  bottom: 21px;
  /* left: 20px; */
  padding: 2px 5px 3px 5px;
  border-radius: 12px;
  max-width: 52px;
  height: 27px;
  background-color: #fff;
  z-index: 800;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

/* .navbar{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
} */
.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #b6b6b9;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 25px 45px 25px;
  border-radius: 15px;
  transition: all 0.3s;
  color: #000;
  max-width: 100%;
}

.auth-inner input {
  color: #000;
}

.auth-inner input.form-control[readonly] {
  color: #000;
}

.auth-inner button {
  background: #000;
  border: 1px solid #fff;
}

.auth-inner input.form-control::placeholder {
  color: #000 !important;
}

.auth-wrapper .form-control:focus {
  border-color: #000;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.width-100 {
  width: 44px;
  height: 24px;
}

.width-100-home-top {
  width: 50px;
  height: 50px;
  border-radius: 10px !important;
  overflow: hidden;
  margin: auto;
  box-shadow: 0 0 6px rgb(3 15 8 / 48%);
}

.forgot-pass {
  width: 50%;
  float: left;

  text-align: left;
}

.register-pass {
  width: 50%;
  float: right;
  text-align: right;
}

.btn-marget-top-10 {
  margin-top: 15px;
}

.font-top-saller-top {
  font-family: "givonic";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  /* line-height   : 1px; */
  color: #8fd2ff;
  /* margin-top    : 15px; */
  text-shadow: -1px 4px 4px #030f08;
  margin-bottom: 0;
}

.font-top-saller-bottom {
  font-family: "givonic";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #8fd2ff;
  text-shadow: -1px 4px 4px #030f08;
  /* margin-top : -10px; */
  margin-bottom: 0;
}

.font-top-saller-number {
  font-family: "givonic";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  margin-top: 30px;
}

.card-footer:last-child {
  border-radius: 0 !important;
}

.nav-link {
  padding: 0.1rem 0.5rem;
  font-family: "givonic";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #5d5d5b !important;
}

.margin-top-20px {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.text-right {
  text-align: right !important;
}

.remove-underline-a-tag>p>a {
  text-decoration: none !important;
  color: #ffffff;
}

.margin-container-bottom {
  margin-top: 50px;
  margin-bottom: 300px !important;
}

.min-body-height {
  min-height: 400px;
}

.padding-top-bottom-50 {
  padding: 50px 50px 50px 50px;
}

.padding-top-bottom-25 {
  padding: 25px 0px 25px 0px;
}

.padding-top0-bottom-25 {
  padding: 0px 0px 25px 0px;
}

.content-container {
  min-height: calc(100vh - 34px);
}

/*///////////////// footer Start//////////////////*/

.footer-heading {
  font-family: "givonic";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  gap: 15px;
  align-items: center;
  cursor: pointer;
}

h4.footer-heading.top-footer {
  margin-top: 50px;
}

.footer-subheading>a {
  font-family: "givonic";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #5d5d5b;
  text-decoration: none;
  cursor: pointer;
}

.iocs-main {
  margin-right: 1rem;
  padding: 0px 8px;
  font-size: 1rem;
}

.iocs-main>a {
  color: #000;
  padding-left: 15px;
}

.width-50-fixed {
  width: 50% !important;
}

.footer-heading>a {
  text-decoration: none;
  color: #000000;
}

/*///////////////// footer end//////////////////*/
.content-deailes h5 {
  font-size: 2rem;
  letter-spacing: 2px;
  font-weight: 300;
}

.content-deailes a {
  color: #000;
  text-decoration: none;
  font-weight: 500;
}

.content-deailes a:hover {
  text-decoration: underline;
  color: #0073b2;
}

.content-deailes {
  padding-top: 3rem;
}

button.btn.Btn-Ftr {
  color: #fff;
  background: #46464c;
  padding: 0.4rem 3rem;
  border-radius: 0;
  margin-top: 5rem;
  margin-bottom: 1rem;
}

.legal-btr p {
  font-weight: 400;
  color: #000;
}

.legal-btr1 p {
  font-weight: 500;
}

.legal-btr1 {
  margin-top: 6rem;
}

.legal-btr1 p:last-child {
  font-size: 13px;
}

.pop-logo {
  text-align: center;
}

.pop-logo img {
  width: 170px;
}

.card-img {
  position: relative;
}

.img-box p {
  color: #fff;
  margin-bottom: 2px;
  font-size: 13px;
}

.model-img {
  width: 50%;
  height: auto;
}

.img-center {
  text-align: center;
}

.proFile img.inner-ing {
  width: 50px;
  height: 50px;
  border-radius: 100% !important;
  margin-right: 0%;
  box-shadow: 0 0 6px rgb(0 0 0 / 35%);
  object-fit: cover;
}

.min-height-250 {
  height: 250px;
}

.min-height-200 {
  height: 200px;
}

.min-height-200-new {
  height: 225px;
  object-fit: cover;
}

.min-height-275 {
  height: 230px;
}

.min-height-590 {
  height: 590px;
}

.a-link-display-none {
  text-decoration: none;
}

.see-all-button {
  text-align: center;
  float: right;
  padding: 5px 14px;
  font-size: 13px;
  border-radius: 20px !important;
  cursor: pointer;
  background: #000;
  color: #fff;
  border: 1px solid #000;
  font-family: givonic;
  box-shadow: 0 0 6px #fff;
}

.see-all-button:hover {
  border: 1px solid #000;
  color: #000;
  background: #fff;
}

.proFile {
  display: flex;
  align-items: center;
}

.img-box {
  position: absolute;
  bottom: 90px;
  left: 41%;
}

.img-box-home {
  position: absolute;
  bottom: 0;
  /* left           : 41%; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.card-body {
  flex: 1 1 auto;
  padding: 0.5rem 1rem 0rem 1rem !important;
}

.detail-page-darkfont {
  font-family: "givonic";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.detail-page-lightfont {
  font-family: "givonic";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #c1bdbd;
}

.detail-page-lightfont a {
  text-decoration: none;
  color: #fff;
  font-weight: 900;
}

.onwfilea {
  text-decoration: none;
}

.cardNFTName {
  color: #000;
  font-family: "givonic";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 24px;
  text-transform: capitalize;
  height: 25px;
}

.cardNFTBY {
  font-family: "givonic";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  margin-top: -2px;
  /* color: #060000; */
}

.cardNFTBYACount {
  /* color: #060000; */
  font-family: "givonic";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

a.lgOut11 {
  border: 1px solid #000000;
  margin-right: 0px;
  border-radius: 23px !important;
  font-size: 15px;
  text-transform: uppercase;
  font-family: "givonic";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  margin-left: 8px;
  padding-top: 10px;
  padding-bottom: 5px;
}

a.lgOut11:hover {
  background: #fff;
}

.connected {
  /* margin-left: 10px; */
  margin-right: 0px;
  background: #000000;
  border-radius: 23px !important;
  font-family: "givonic";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}

a#collasible-nav-dropdown {
  border: 1px solid #2600ff;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 15px;
  text-transform: uppercase;
}

a.bell-iocns.dropdown-item {
  position: relative;
}

a.bell-iocns.dropdown-item:after {
  content: "\f0f3";
  font-family: fontawesome;
  margin-left: 5px;
}

a.wallet-icons.dropdown-item {
  position: relative;
}

a.wallet-icons.dropdown-item:after {
  content: "\f555";
  font-family: fontawesome;
  margin-left: 5px;
}

.dropdown-menu.show {
  background: #ccc;
}

a.dropdown-item {
  color: #000;
}

hr.dropdown-divider {
  color: #7c7c7c;
}

.auth-wrapper h3 {
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 3rem 0.5rem;
  margin: 0 auto;
  color: #000 !important;
  border-radius: 12px;
  font-size: 34px;
  /* border-bottom: 6px solid #000; */
  /* text-shadow: -1px 4px 4px #000; */
  font-weight: bold;
}

.header-input-color {
  color: #f2f2f3 !important;
}

.nft-see-all {
  color: #000;
  float: right;
  padding: 5px;
  /* border      : 1px solid #0a58ca; */
  font-size: 13px;
  border-radius: 5px;
  margin-top: 10px;
  text-transform: uppercase;
  text-decoration: none;
}

.nft-see-all:hover {
  color: #198754;
}

.product-list-border {
  border: 1px solid #2600ff;
}

.product-list-border-right {
  border-right: 1px solid #2600ff;
}

.product-list-border-left {
  border-right: 1px solid #2600ff;
}

.margin-top-bottom-15 {
  margin-top: 15px !important;
  margin-bottom: 15px;
}

.margin-pagination {
  margin-left: 0px !important;
  margin-top: 15px !important;
}

.border-bottom-list-page {
  border-bottom: 1px solid #2600ff;
}

.padding-left-right-0 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.form-label-0 {
  margin-bottom: 0px !important;
}

.padding-top-bottom-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.padding-15 {
  padding: 15px;
}

.color-white {
  color: #000;
}

.margin-bottommb {
  margin-bottom: 0px !important;
}

.checkbox-nft-lst {
  height: 30px;
  width: 30px;
}

.checkbox-privacy-policy {
  height: 14px;
  width: 25px;
}

.padding-top-bottom-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.BuyNFT-button {
  height: 48px;
  /* width     : 100%; */
  cursor: pointer;
  border-radius: 30px !important;
  border: 0 !important;
  box-shadow: -1px 3px 4px rgba(88, 83, 83, 67%),
    inset -1px -3px 4px rgba(88, 83, 83, 46%);
  margin-top: 20px;
  min-width: 180px;
}

.box {
  width: 100%;
  border: 1px solid #5d5d5b;
  margin-bottom: -1px;
}

.token-info {
  text-align: center;
  font-weight: bold;
  border-radius: 15px;
  background-color: #b6b6b9;
  padding: 15px 0px 15px 0px;
  color: #000;
  font-size: 20px;
}

.highest-offer {
  border: 1px solid #2600ff;
  background-color: #198754;
  text-align: center;
  color: #ffffff;
  border-radius: 15px;
  font-size: 20px;
  font-weight: bold;
  padding: 15px 0px 15px 0px;
}

.current-bid {
  border: 1px solid #2600ff;
  background-color: #198754;
  text-align: center;
  color: #ffffff;
  border-radius: 15px;
  font-size: 20px;
  font-weight: bold;
  padding: 15px 0px 15px 0px;
}

.timer {
  border: 1px solid #fff;
  background-color: #7c7c7c;
  text-align: center;
  color: #000;
  border-radius: 15px;
  font-size: 20px;
  font-weight: bold;
  padding: 15px 0px 15px 0px;
}

.advertise {
  border: 15px solid #2600ff;
  text-align: center;
  font-weight: bold;
  border-radius: 15px;
  background-color: black;
  /* padding      : 125px 0px 125px 0px; */
  color: white;
  font-size: 20px;
}

.advertise>img {
  min-height: 370px;
}

.bid-xdx {
  border: 1px solid #fff;
  background-color: #7c7c7c;
  text-align: center;
  color: #000;
  border-radius: 15px;
  font-size: 20px;
  font-weight: bold;
  padding: 15px 0px 15px 0px;
}

.o-xdx {
  border: 1px solid #2600ff;
  background-color: #ffffff;
  text-align: center;
  color: #000;
  border-radius: 15px;
  font-size: 17px;
  font-weight: bold;
  padding: 15px 15px 15px 15px;
  margin-top: 8px;
  width: 100%;
  margin-bottom: 10px;
}

.width-50 {
  width: 25%;
}

.btn-css {
  margin-top: 8px;
}

.anti-bot {
  border: 1px solid #fff;
  background-color: #7c7c7c;
  text-align: center;
  color: #fff;
  border-radius: 15px;
  font-size: 20px;
  font-weight: bold;
  padding: 15px 0px 15px 0px;
}

.transaction-history,
.nft-register {
  border: 1px solid #0f0063;
  background-color: #2600ff;
  text-align: center;
  color: #fff;
  border-radius: 15px;
  font-size: 20px;
  font-weight: bold;
  padding: 15px 0px 15px 0px;
}

.address-rewards {
  border: 1px solid #0f0063;
  background-color: #2600ff;
  text-align: center;
  color: #fff;
  border-radius: 15px;
  padding: 15px 0px 15px 0px;
  font-size: 20px;
}

/* timer */

h1 {
  font-family: "givonic";
  text-align: center;
  margin-bottom: 40px;
}

.timer-wrapper {
  display: flex;
  justify-content: center;
}

.timer {
  border: none;
  background-color: transparent;
  text-align: center;
  color: #000;
  font-family: "givonic";
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 !important;
}

.text {
  color: #000;
}

.value {
  font-size: 60px;
}

.info {
  max-width: 360px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}

.timer-wrapper>img,
.timer-wrapper>svg {
  vertical-align: middle;
  display: none;
}

.cptcha>div>div>div {
  margin: auto;
}

.timer-wrapper>div {
  height: 130px !important;
}

.p-color-bid {
  color: #2e2a2a;
  font-size: 17px;
}

.NFTokenID {
  color: #565656;
  margin-bottom: 20px;
}

.font-14 {
  font-size: 14px;
}

.modal-content {
  border-radius: 30px !important;
}

.padding-modal-image {
  padding: 30px;
}

.rounded {
  border-radius: 1.25rem !important;
}

.overflowScroller {
  max-height: 330px;
  overflow-y: scroll;
}

.hr-style {
  height: 10px !important;
  color: red;
  width: 95%;
  margin: auto;
}

.overflowScroller::-webkit-scrollbar-track {
  border: 1px solid #000;
  padding: 2px 0;
  background-color: #404040;
}

.overflowScroller::-webkit-scrollbar {
  width: 10px;
}

.overflowScroller::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #737272;
  border: 1px solid #000;
}

.selfCrousel {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  overflow: auto;
}

.selfCrousel .img_preview_file {
  margin-right: 10px;
}

.selfCrousel .pdf-preview-cstm {
  width: 133px;
}

.modal-body .main-img .home-music audio {
  left: 0;
}

.listImgSection .customShado .img-box-home {
  bottom: -7px;
}

.innerBoxes {
  word-break: break-all;
  width: 100%;
  margin-bottom: 8px;
  background-color: rgba(21, 178, 229, 0.06);
  border-radius: 6px;
  border: 1px solid rgb(21, 178, 229);
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.innerBoxes p,
.innerBoxes h6 {
  margin: 0;
  width: 50%;
  text-align: left;
  font-size: 14px;
}

.mainBox {
  padding: 9px 13px;
  border: 1px solid rgb(229, 232, 235);
}

.propertyBox {
  display: flex;
  flex-wrap: wrap;
}

.web-none {
  display: none;
}

.web-non-box {
  display: block;
}

/*////////////////////////////////////*/
.home-hading {
  font-family: "givonic";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  color: #000;
  /* text-shadow: -1px 4px 4px #000; */
}

.datawrap {
  white-space: nowrap;
  width: 31% !important;
}

/*////////////////////////////////////*/

/* timer end */
.main-img {
  position: relative;
  /* margin-bottom: 1rem; */
}

.detail_nft {
  width: auto;
  height: 100%;
  border: solid 1px #dfdfdf;
  /* padding            : 1%; */
  border-radius: 18px;
  transition-duration: 0.5s;
  /* box-shadow: -4px 5px 15px 5px #000000; */
}

/* .detail_nft:hover {
  width        : auto;
  height       : 25em;
  border       : solid 1px #dfdfdf;
  padding      : 1%;
  box-shadow   : -4px 5px 15px 5px #000000;
  border-radius: 18px;
} */

.min-height-200-new11 {
  width: 100%;
  height: 100%;
  /* object-fit: scale-down; */
  object-fit: inherit;
}

/* 28-07-2022 */
.NFTokenIDcustom {
  overflow: hidden;
  text-overflow: ellipsis;
}

.h-auto-custom {
  height: auto;
  width: 100%;
}

.totalcount {
  padding: 4px 9px 2px 9px;
  border-radius: 6px;
  background-color: #000;
  color: white;
  font-weight: 400;
}

.audio-video-tag {
  width: 100%;
  height: 315px;
  margin-bottom: 14px;
  background: url("/src/assets/rd.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  border-radius: 1rem;
  position: relative;
}

/* 
.video-tag {
  width            : 100%;
  height           : 226px !important;
  margin-bottom    : 14px;
  background-repeat: no-repeat;
  background-size  : cover;
} */

.audio-video-tag-common {
  width: 100%;
  height: 340px;
  /* background-color: #46464c; */
  margin-bottom: 14px;
  background: url("/src/assets/rd.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.video-tag-common {
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.video-player {
  width: 100% !important;
  height: 100%;
  position: relative;
}

audio {
  width: 100%;
  position: relative;
  /* top           : 50%; */
  top: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  /* transform     : translateY(-50%); */
  transform: translateY(-20%);
}

.only-for-mobile {
  display: none;
}

/* New NFT cards CSS */
.arrowdown-img {
  width: 25px;
  margin: auto;
}

.arrowdown-img img {
  width: 100%;
}

.cardnft-text.card-h {
  height: 120px;
  opacity: 1;
}

.card-h0 {
  height: 0;
  opacity: 0;
}

.cardnft-text {
  position: relative;
  transition: 0.4s;
  overflow: hidden;
}

/* .token-badge {
  position: absolute;
  top: 35px;
  right: -75px;
  z-index: 100;
  width: 120px;
  height: 120px;
} */

.token-badge {
  position: relative;
  top: 0;
  right: -62px;
  z-index: 100;
  width: 115px;
}

.home-css.token-badge.customTokenbadge {
  position: relative;
  top: 0;
  right: -50px;
  z-index: 100;
  width: 64px;
}

.tick-badge {
  position: relative;
  top: 0;
  right: -30px;
  z-index: 100;
  width: 120px;
}

/* .tick-badge {
  position: absolute;
  top: 70px;
  right: -75px;
  z-index: 100;
  width: 120px;
  height: 120px;
} */

@media (max-width: 991px) {
  .only-for-mobile {
    display: block;
  }
}

@media screen and (max-width: 420px) {
  .homeNav {
    position: absolute !important;
    right: 51px !important;
    top: 15px !important;
  }

  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  /* .only-for-mobile {
    display: block;
  } */
  .width-25 {
    width: 16% !important;
    height: 45px !important;
  }

  .token-badge {
    position: relative;
    top: 0;
    right: -33px;
    z-index: 100;
    width: 79px;
  }

  input[type="file"] {
    color: rgba(0, 0, 0, 0);
  }
}

.audio-height {
  background-size: 360px 360px;
  /* background-position: center; */
  background-position: top;
}

.width-22 {
  width: 22%;
}

.width-25 {
  /* width: 17%; */
  height: 25px;
}

.width-3 {
  width: 3%;
}

.width-48 {
  width: 48%;
}

.nft-details {
  color: #ccc;
}

.qr-img {
  width: 100%;
  height: auto;
}

.label-uppercase {
  text-transform: uppercase;
}

.margin-top1 {
  margin-top: 1%;
}

.loader_position {
  position: absolute;
  left: 47%;
  top: 71.5%;
}

.img_preview {
  width: 133px;
  height: 133px;
}

.img_max_preview {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.audio-height_img_preview {
  background-size: 133px 133px;
  background-position: center;
}

/* to overide default style */
canvas.react-pdf__Page__canvas {
  width: 100% !important;
  height: 260px !important;
  /* border-radius: 1rem; */
  margin-bottom: 15px;
}

.search-body {
  margin-top: 50px;
}

.css-yymkrn {
  position: absolute !important;
  left: 50%;
  top: 50%;
  width: 0px !important;
  height: 0px !important;
}

.parentDisable {
  position: fixed;
  top: 0;
  left: 0;
  background: #ffff;
  opacity: 0.92;
  z-index: 998;
  height: 100%;
  width: 100%;
}

.bg-black {
  background-color: #000;
}

.bg-white {
  background-color: #fff;
}

.home-music audio {
  position: absolute;
  top: 50%;
}

.header-image {
  width: 100%;
  height: 40px;
}

/* .shadowcstm{
  box-shadow: -12px -12px 5px rgb(0 0 0 / 30%);
  overflow: hidden;
} */
.shadowcstm .card-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
  /* margin: 0 auto; */
  /* width: 100%; */
}

.shadowcstm .card-body p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  width: 100%;
}


.home-shadow-css .card-body p {
  white-space: nowrap;
  overflow: visible !important;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  width: 100%;
}

.cardNFTName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.previewmusiccstm>div {
  position: relative;
}

.previewmusiccstm audio {
  position: absolute;
  top: 39%;
  height: 25px;
}

.pdf-preview-cstm {
  height: 133px;
  overflow: hidden;
  margin-bottom: 1rem;
}

.pdf-preview-cstm .react-pdf__Document.pdf-style,
.pdf-preview-cstm .react-pdf__Page.pdf-style {
  height: 100%;
}

.pdf-preview-cstm canvas.react-pdf__Page__canvas {
  border-radius: 1rem;
  height: 100% !important;
}

/* .react-multi-carousel-list {
  padding: 0 !important;
} */

/* *****************CUSTOM************************ */
.min-height-200-new {
  height: 260px;
  object-fit: cover;
  /* border : 1px solid #000; */
  border: 4px solid #fff;
}

.video-tag {
  width: 100%;
  height: 260px;
  margin-bottom: 14px;
  background-repeat: no-repeat;
  background-size: cover;
  /* border           : 4px solid #fff; */
  border-radius: 1rem;
}

.video-player {
  width: 100% !important;
  height: 100%;
  position: relative;
  /* top       : 50%; */
  /* transform : translateY(-50%); */
  object-fit: cover;
  /* border-radius: 1rem; */
}

.customShado {
  /* border: 20px solid #212529 !important; */
  background-image: radial-gradient(#fff, #fff, #fff) !important;
  box-shadow: -4px 4px 10px #ffffff94, inset #fff 5px -6px 8px;
}

/* background-image: #fff;
box-shadow: -4px 4px 10px #ffffff94, inset #fff 5px -6px 8px; */

.customShado.rounded {
  border-radius: 1rem !important;
  padding: 25px 0px 0px 0px !important;
}

/* a.a-link-display-none .pdf-style,
a.a-link-display-none .main-img {
  height: 226px;
} */

.likedSection .react-pdf__Document.pdf-style .react-pdf__Page__textContent {
  object-fit: cover;
  width: 100% !important;
  position: relative !important;
  height: 100% !important;
}

ul.react-multi-carousel-track li .img-box-home {
  bottom: -7px;
}

.likedSection .img-box-home {
  bottom: -24px;
}

.ownNftSection .react-pdf__Page.pdf-style .react-pdf__Page__textContent {
  width: 100% !important;
  height: 100% !important;
}

.ownNftSection .react-pdf__Page.pdf-style .react-pdf__Page__textContent span {
  display: none;
}

.search-body.container .react-pdf__Page.pdf-style .react-pdf__Page__textContent {
  width: 100% !important;
}

.productDetailView .pdf-style .react-pdf__Document.pdf-style {
  border: solid 1px #dfdfdf;
  padding: 1%;
  border-radius: 18px;
  transition-duration: 0.5s;
  box-shadow: -4px 5px 15px 5px #b7b7b778;
}

.productDetailView .react-pdf__Page.pdf-style .react-pdf__Page__textContent {
  width: 100% !important;
}

p.detail-page-lightfont.fullAddrsss {
  word-break: break-all;
}

/* =========ribbon design start=========== */
.corner-ribbon .frst {
  display: block;
  position: relative;
}

/* ----- Ribbon Container ---------------------------------------*/
.corner-ribbon .frst .scnd {
  background: transparent;
  height: 200px;
  width: 100%;
  position: absolute;
  top: -10px;
  left: 10px;
  overflow: hidden;
}

/* ----- Top Curl ---------------------------------------*/
.corner-ribbon .frst .scnd:before {
  content: "";
  display: block;
  -webkit-border-radius: 10px 10px 0px 0px;
  border-radius: 10px 10px 0px 0px;
  width: 28px;
  height: 10px;
  position: absolute;
  right: 86px;

  background: rgb(0, 138, 59);
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIxJSIgc3RvcC1jb2xvcj0iIzAwOGEzYiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjQ1JSIgc3RvcC1jb2xvcj0iIzBmMzMwYSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
  background: -moz-linear-gradient(left,
      rgba(0, 138, 59, 1) 1%,
      rgba(15, 51, 10, 1) 45%);
  /* FF3.6+ */
  background: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(1%, rgba(0, 138, 59, 1)),
      color-stop(45%, rgba(15, 51, 10, 1)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left,
      rgba(0, 138, 59, 1) 1%,
      rgba(15, 51, 10, 1) 45%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left,
      rgba(0, 138, 59, 1) 1%,
      rgba(15, 51, 10, 1) 45%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left,
      rgba(0, 138, 59, 1) 1%,
      rgba(15, 51, 10, 1) 45%);
  /* IE10+ */
  background: linear-gradient(to right,
      rgba(0, 138, 59, 1) 1%,
      rgba(15, 51, 10, 1) 45%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#008a3b', endColorstr='#0f330a', GradientType=1);
  /* IE6-8 */
}

/* ----- Right Curl ---------------------------------------*/
.corner-ribbon .frst .scnd:after {
  content: "";
  display: block;
  -webkit-border-radius: 0px 10px 10px 0px;
  border-radius: 0px 10px 10px 0px;
  width: 10px;
  height: 31px;
  position: absolute;
  right: 0px;
  top: 85px;

  background: rgb(15, 51, 10);
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSI1NSUiIHN0b3AtY29sb3I9IiMwZjMzMGEiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSI5OSUiIHN0b3AtY29sb3I9IiMwMDhhM2IiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top,
      rgba(15, 51, 10, 1) 55%,
      rgba(0, 138, 59, 1) 99%);
  /* FF3.6+ */
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(55%, rgba(15, 51, 10, 1)),
      color-stop(99%, rgba(0, 138, 59, 1)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,
      rgba(15, 51, 10, 1) 55%,
      rgba(0, 138, 59, 1) 99%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,
      rgba(15, 51, 10, 1) 55%,
      rgba(0, 138, 59, 1) 99%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top,
      rgba(15, 51, 10, 1) 55%,
      rgba(0, 138, 59, 1) 99%);
  /* IE10+ */
  background: linear-gradient(to bottom,
      rgba(15, 51, 10, 1) 55%,
      rgba(0, 138, 59, 1) 99%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0f330a', endColorstr='#008a3b', GradientType=0);
  /* IE6-8 */
}

/* ----- Ribbon ---------------------------------------*/
.corner-ribbon .frst .scnd .thrd {
  width: 100%;
  height: 27px;
  position: absolute;
  top: 30px;
  right: -120px;
  z-index: 1;
  overflow: hidden;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -moz-transform: rotate(45deg);
  /* Firefox */
  -webkit-transform: rotate(45deg);
  /* Safari and Chrome */
  -o-transform: rotate(45deg);
  /* Opera */
  box-shadow: inset 0px 0px 0px 3px rgb(0, 114, 45),
    inset 0px 0px 0px 4px rgba(0, 0, 0, 0.5),
    inset 0px 0px 0px 5px rgba(255, 255, 255, 0.4),
    0px 21px 5px -18px rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: inset 0px 0px 0px 3px rgb(0, 114, 45),
    inset 0px 0px 0px 4px rgba(0, 0, 0, 0.5),
    inset 0px 0px 0px 5px rgba(255, 255, 255, 0.4),
    0px 21px 5px -18px rgba(0, 0, 0, 0.8);

  background: rgb(0, 114, 45);
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwNzIyZCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUxJSIgc3RvcC1jb2xvcj0iIzAwOGEzYiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDU2MWMiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(left,
      rgba(0, 114, 45, 1) 0%,
      rgba(0, 138, 59, 1) 51%,
      rgba(0, 86, 28, 1) 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(0%, rgba(0, 114, 45, 1)),
      color-stop(51%, rgba(0, 138, 59, 1)),
      color-stop(100%, rgba(0, 86, 28, 1)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left,
      rgba(0, 114, 45, 1) 0%,
      rgba(0, 138, 59, 1) 51%,
      rgba(0, 86, 28, 1) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left,
      rgba(0, 114, 45, 1) 0%,
      rgba(0, 138, 59, 1) 51%,
      rgba(0, 86, 28, 1) 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left,
      rgba(0, 114, 45, 1) 0%,
      rgba(0, 138, 59, 1) 51%,
      rgba(0, 86, 28, 1) 100%);
  /* IE10+ */
  background: linear-gradient(to right,
      rgba(0, 114, 45, 1) 0%,
      rgba(0, 138, 59, 1) 51%,
      rgba(0, 86, 28, 1) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00722d', endColorstr='#00561c', GradientType=1);
  /* IE6-8 */
  box-shadow: none !important;
}

/* ----- Top Glare ---------------------------------------*/
.corner-ribbon .frst .scnd .thrd:before {
  content: "";
  display: block;
  height: 72px;
  width: 13px;
  position: relative;
  top: -13px;
  left: 27px;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);

  /* IE9 SVG, needs conditional override of 'filter' to 'none' */

  background: -moz-linear-gradient(left,
      rgba(255, 255, 255, 0) 1%,
      rgba(255, 255, 255, 0.6) 50%,
      rgba(255, 255, 255, 0) 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(1%, rgba(255, 255, 255, 0)),
      color-stop(50%, rgba(255, 255, 255, 0.6)),
      color-stop(100%, rgba(255, 255, 255, 0)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left,
      rgba(255, 255, 255, 0) 1%,
      rgba(255, 255, 255, 0.6) 50%,
      rgba(255, 255, 255, 0) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left,
      rgba(255, 255, 255, 0) 1%,
      rgba(255, 255, 255, 0.6) 50%,
      rgba(255, 255, 255, 0) 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left,
      rgba(255, 255, 255, 0) 1%,
      rgba(255, 255, 255, 0.6) 50%,
      rgba(255, 255, 255, 0) 100%);
  /* IE10+ */
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 1%,
      rgba(255, 255, 255, 0.6) 50%,
      rgba(255, 255, 255, 0) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#00ffffff', GradientType=1);
  /* IE6-8 */
}

/* ----- Right Clear ---------------------------------------*/
.corner-ribbon .frst .scnd .thrd:after {
  content: "";
  display: block;
  height: 72px;
  width: 13px;
  position: relative;
  bottom: 90px;
  left: 154px;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(45deg);

  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIxJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwLjYiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
  background: -moz-linear-gradient(left,
      rgba(255, 255, 255, 0) 1%,
      rgba(255, 255, 255, 0.6) 50%,
      rgba(255, 255, 255, 0) 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(1%, rgba(255, 255, 255, 0)),
      color-stop(50%, rgba(255, 255, 255, 0.6)),
      color-stop(100%, rgba(255, 255, 255, 0)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left,
      rgba(255, 255, 255, 0) 1%,
      rgba(255, 255, 255, 0.6) 50%,
      rgba(255, 255, 255, 0) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left,
      rgba(255, 255, 255, 0) 1%,
      rgba(255, 255, 255, 0.6) 50%,
      rgba(255, 255, 255, 0) 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left,
      rgba(255, 255, 255, 0) 1%,
      rgba(255, 255, 255, 0.6) 50%,
      rgba(255, 255, 255, 0) 100%);
  /* IE10+ */
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 1%,
      rgba(255, 255, 255, 0.6) 50%,
      rgba(255, 255, 255, 0) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#00ffffff', GradientType=1);
  /* IE6-8 */
}

.bid.corner-ribbon .frst .scnd .thrd {
  background: linear-gradient(to right,
      rgb(255, 0, 0) 0%,
      rgb(255, 0, 0) 51%,
      rgb(255, 0, 0) 100%);
}

.bid.corner-ribbon .frst .scnd:before {
  background: linear-gradient(to right, rgb(255, 0, 0), rgb(116 16 16) 45%);
}

.bid.corner-ribbon .frst .scnd:after {
  background: linear-gradient(to right, rgb(255, 0, 0), rgb(116 16 16) 45%);
}

.minted.corner-ribbon .frst .scnd .thrd {
  background: linear-gradient(to right,
      rgb(53, 152, 219) 0%,
      rgb(53, 152, 219) 51%,
      rgb(53, 152, 219) 100%);
}

.minted.corner-ribbon .frst .scnd:before {
  background: linear-gradient(to right,
      rgb(53, 152, 219) 1%,
      rgb(19 60 87) 45%);
}

.minted.corner-ribbon .frst .scnd:after {
  background: linear-gradient(to right,
      rgb(53, 152, 219) 1%,
      rgb(19 60 87) 45%);
}

.created.corner-ribbon .frst .scnd .thrd {
  background: linear-gradient(to right,
      rgb(128, 128, 128) 0%,
      rgb(128, 128, 128) 51%,
      rgb(128, 128, 128) 100%);
}

.created.corner-ribbon .frst .scnd:before {
  background: linear-gradient(to right,
      rgb(128, 128, 128) 1%,
      rgb(143 99 17) 45%);
}

.created.corner-ribbon .frst .scnd:after {
  background: linear-gradient(to right,
      rgb(128, 128, 128) 1%,
      rgb(143 99 17) 45%);
}

/* ==============ribbon design end================ */
.NFTList .img-box-home {
  bottom: -20px;
}

@media (min-width: 1400px) {
  .carousal-main.container .react-multi-carousel-list ul li>div .frst .scnd .thrd {
    right: -100px;
  }
}

@media only screen and (max-width: 1400px) {
  .corner-ribbon .frst .scnd .thrd {
    right: -87px;
  }

  .ownNft.row .corner-ribbon .frst .scnd .thrd {
    right: -86px;
  }
}

@media only screen and (max-width: 1200px) {
  .ownNft.row .corner-ribbon .frst .scnd .thrd {
    right: -66px;
  }

  .corner-ribbon .frst .scnd .thrd {
    right: -60px;
  }
}

@media (max-width: 1024px) and (min-width: 992px) {
  .nftHomePage .corner-ribbon .frst .scnd .thrd {
    right: -177px;
  }
}

@media only screen and (max-width: 991px) {
  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .corner-ribbon .frst .scnd .thrd {
    right: -115px;
  }

  .ownNft.row .corner-ribbon .frst .scnd .thrd {
    right: -115px;
  }
}

@media only screen and (max-width: 767px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .width-25 {
    width: 16% !important;
    height: 45px !important;
  }

  .cstmporight {
    position: absolute;
    top: 40px;
    right: -5px;
  }

  .validArtist p {
    gap: 10px !important;
  }

  .profileInput .form-label,
  .validArtist .form-label,
  .validScore .form-label {
    width: 30% !important;
  }

  .corner-ribbon .frst .scnd .thrd {
    right: -225px;
  }

  .ownNft.row .corner-ribbon .frst .scnd .thrd {
    right: -200px;
  }

  .carousal-main.container .react-multi-carousel-list ul li>div .frst .scnd .thrd {
    right: -192px;
  }

  .min-body-height .mobileName {
    display: block;
  }

  .deskName {
    display: none;
  }
}

@media only screen and (max-width: 580px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .width-25 {
    width: 16% !important;
    height: 45px !important;
  }

  .likedSection {
    padding: 0px 35px;
  }

  .web-none {
    display: block;
  }

  .web-non-box {
    display: none;
  }
}

@media only screen and (max-width: 540px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .width-25 {
    width: 16% !important;
    height: 45px !important;
  }

  .corner-ribbon .frst .scnd .thrd {
    right: -190px;
  }

  .ownNft.row .corner-ribbon .frst .scnd .thrd {
    right: -180px;
  }

  .carousal-main.container .react-multi-carousel-list ul li>div .frst .scnd .thrd {
    right: -188px;
  }

  /* .margin-top-20px .sellingCard .sellingLeft img {
    width : 50px;
    height: 50px;
    margin: 0 45px 0 25px;
  } */

  .sellingCard .sellingLeft p.font-top-saller-number {
    margin-top: 15px;
  }

  .sellingCard .sellingRight p:first-child {
    margin-top: 0;
  }

  /* .sellingCard .sellingRight p:last-child {
    margin-bottom: 0;
    margin-top   : -15px;
  } */

  /* .topSellingP.margin-top-20px {
    margin: 5px 0 !important;

  } */
}

@media only screen and (max-width: 480px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .width-25 {
    width: 16% !important;
    height: 45px !important;
  }

  .carousal-main.container .react-multi-carousel-list ul li>div .frst .scnd .thrd {
    right: -164px;
  }
}

@media only screen and (max-width: 460px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .width-25 {
    width: 16% !important;
    height: 45px !important;
  }

  .corner-ribbon .frst .scnd .thrd {
    right: -176px;
  }

  .ownNft.row .corner-ribbon .frst .scnd .thrd {
    right: -149px;
  }

  .carousal-main.container .react-multi-carousel-list ul li>div .frst .scnd .thrd {
    right: -150px;
  }
}

@media only screen and (max-width: 440px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .width-25 {
    width: 16% !important;
    height: 45px !important;
  }

  .corner-ribbon .frst .scnd .thrd {
    right: -158px;
  }

  .ownNft.row .corner-ribbon .frst .scnd .thrd {
    right: -140px;
  }

  .carousal-main.container .react-multi-carousel-list ul li>div .frst .scnd .thrd {
    right: -141px;
  }

  .auth-wrapper.App.padding-top-bottom-50 {
    padding: 0;
  }
}

@media only screen and (max-width: 420px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .width-25 {
    width: 16% !important;
    height: 45px !important;
  }

  .ownNft.row .corner-ribbon .frst .scnd .thrd {
    right: -135px;
  }

  .carousal-main.container .react-multi-carousel-list ul li>div .frst .scnd .thrd {
    right: -129px;
  }
}

@media only screen and (max-width: 400px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .width-25 {
    width: 16% !important;
    height: 45px !important;
  }

  .corner-ribbon .frst .scnd .thrd {
    right: -135px;
  }

  .ownNft.row .corner-ribbon .frst .scnd .thrd {
    right: -118px;
  }

  .carousal-main.container .react-multi-carousel-list ul li>div .frst .scnd .thrd {
    right: -119px;
  }
}

@media only screen and (min-width: 376px) and (max-width: 395px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .width-25 {
    width: 16% !important;
    height: 45px !important;
  }

  .zerofees {
    margin-left: 27% !important;
  }
}

@media only screen and (max-width: 375px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .width-25 {
    width: 16% !important;
    height: 45px !important;
  }

  .ownNft.row .corner-ribbon .frst .scnd .thrd {
    right: -108px;
  }

  .carousal-main.container .react-multi-carousel-list ul li>div .frst .scnd .thrd {
    right: -109px;
  }
}

@media only screen and (max-width: 360px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .width-25 {
    width: 16% !important;
    height: 45px !important;
  }

  .corner-ribbon .frst .scnd .thrd {
    right: -123px;
  }

  .ownNft.row .corner-ribbon .frst .scnd .thrd {
    right: -100px;
  }

  .carousal-main.container .react-multi-carousel-list ul li>div .frst .scnd .thrd {
    right: -98px;
  }
}

@media only screen and (max-width: 340px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .width-25 {
    width: 16% !important;
    height: 45px !important;
  }

  .carousal-main.container .react-multi-carousel-list ul li>div .frst .scnd .thrd {
    right: -92px;
  }
}

@media only screen and (max-width: 320px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .width-25 {
    width: 16% !important;
    height: 45px !important;
  }

  .carousal-main.container .react-multi-carousel-list ul li>div .frst .scnd .thrd {
    right: -85px;
  }
}

@media only screen and (max-width: 330px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .width-25 {
    width: 16% !important;
    height: 45px !important;
  }

  .corner-ribbon .frst .scnd .thrd {
    right: -105px;
  }

  .ownNft.row .corner-ribbon .frst .scnd .thrd {
    right: -85px;
  }
}

.sellingCard {
  display: flex;
  align-items: center;
  text-align: left;
  gap: 20px;
  height: 100%;
}

.sellingCard .sellingLeft {
  display: flex;
  align-items: center;
}

.sellingCard .sellingLeft img {
  margin: 0 10px 0 10px;
}

.detailsBtn {
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 1200px) {
  .detailsBtn button {
    width: 48% !important;
    margin-bottom: 10px;
  }
}

.mobileName {
  display: none;
}

.todayPicks .img-box-home {
  bottom: -7px;
}

.faPlusIcan {
  cursor: pointer;
}

div#pop-body {
  max-height: 250px;
  overflow-x: hidden;
}

button.intro-button.btn.btn-default {
  border: 1px solid #000000;
  border-radius: 23px;
  position: relative;
  right: 8px;
}

.mynftText {
  display: flex;
}

.imgRadius {
  /* border-radius: 1rem; */
  border: 0;
  /* border-top-left-radius: 1rem !important; */
  /* border-top-right-radius: 1rem !important; */
}

.likeCount {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 2px solid #e571e9;
  border-radius: 22px;
  max-width: 65px;
  width: 65px;
  height: 36px;
  padding: 0;
  cursor: pointer;
}

.likeCount .redHeart {
  border: 2px solid #e571e9;
  border-radius: 100%;
  padding: 4px;
  box-shadow: 0 2px 3px rgb(0 0 0 / 16%);
  margin-left: -2px;
  margin-right: 5px;
  cursor: pointer;
}

.corner-ribbon {
  position: relative;
}

/* .corner-ribbon::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 40px;
  right: -14px;
  z-index: 100;
  width: 15px;
  height: 15px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
 
} */

.corner-ribbon::after {
  content: "";
  position: absolute;
  top: 16px;
  right: 23px;
  z-index: 100;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(./assets/Sale.png);
}

.corner-ribbon.sale::after {
  background-image: url(./assets/Sale.png);
}

.minted.corner-ribbon::after {
  background-image: url(./assets/Minted.png);
}

.bid.corner-ribbon::after {
  background-image: url(./assets/bid.png);
}

.created.corner-ribbon::after {
  background-image: url(./assets/created.png);
}

.send.corner-ribbon::after {
  background-image: url(./assets/send.png);
}

.boldhead {
  color: #000;
  font-weight: 900;
  font-size: 15px;
  width: 20%;
  margin-right: 15px;
  text-align: start;
  position: relative;
}

.boldhead::after {
  content: ":";
  position: absolute;
  right: -4px;
}

@media (max-width: 780px) {
  p.body-cart-para {
    font-size: 12px !important;
  }

  div.body-cart-para {
    font-size: 12px !important;
  }

  p.right-item div {
    font-size: 12px !important;
  }

  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  /* .corner-ribbon::after {
    width: 5rem;
  } */

  .mobilePadding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  /* .boldhead{
    width: 60%;
  } */
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 338px;
  margin: 0 auto;
}

@media (max-width: 500px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .width-25 {
    width: 16% !important;
    height: 45px !important;
  }

  .card {
    width: 310px;
  }

  .swiper-slide .card {
    width: 260px;
  }

  .gradientBg {
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
  }
}

/* Like button Css */

.like-button {
  padding: 0.25rem 0.35rem;
  color: #878787;
  font-weight: bold;
  display: flex;
  /* align-items     : self-start; */
  /* justify-content : flex-start; */
  /* font-size       : 1.5rem; */
  transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  filter: grayscale(100%);
  -webkit-user-select: none;
  user-select: none;
  border: 2px solid #e571e9;
  border-radius: 100%;
  padding: 4px;
  box-shadow: 0 2px 3px rgb(0 0 0 / 16%);
  margin-right: 3px;
}

.like-button.liked {
  filter: grayscale(0);
}

/* .like-button:hover {
  border-color: currentColor;
} */

.like-icon {
  width: 20px;
  height: 20px;
  /* display     : inline-block; */
  position: relative;
  margin-right: 0;
  background: url(./assets/like.svg) no-repeat center;
  background-size: 100%;
  animation: heartUnlike 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

.liked .like-icon {
  -webkit-animation: heartPulse 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: heartPulse 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

.liked .like-icon [class^="heart-animation-"] {
  background: url("./assets/like.svg") no-repeat center;
  background-size: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 14px;
  opacity: 0;
}

.liked .like-icon [class^="heart-animation-"]::before,
.liked .like-icon [class^="heart-animation-"]::after {
  content: "";
  background: inherit;
  background-size: 100%;
  width: inherit;
  height: inherit;
  display: inherit;
  position: relative;
  top: inherit;
  left: inherit;
  opacity: 0;
}

.liked .like-icon .heart-animation-1 {
  -webkit-animation: heartFloatMain-1 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: heartFloatMain-1 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

.liked .like-icon .heart-animation-1::before,
.liked .like-icon .heart-animation-1::after {
  width: 12px;
  height: 10px;
  visibility: hidden;
}

.liked .like-icon .heart-animation-1::before {
  opacity: 0.6;
  -webkit-animation: heartFloatSub-1 1s 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: heartFloatSub-1 1s 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

.liked .like-icon .heart-animation-1::after {
  -webkit-animation: heartFloatSub-2 1s 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: heartFloatSub-2 1s 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  opacity: 0.75;
}

.liked .like-icon .heart-animation-2 {
  -webkit-animation: heartFloatMain-2 1s 0.1s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: heartFloatMain-2 1s 0.1s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

.liked .like-icon .heart-animation-2::before,
.liked .like-icon .heart-animation-2::after {
  width: 10px;
  height: 8px;
  visibility: hidden;
}

.liked .like-icon .heart-animation-2::before {
  -webkit-animation: heartFloatSub-3 1s 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: heartFloatSub-3 1s 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  opacity: 0.25;
}

.liked .like-icon .heart-animation-2::after {
  -webkit-animation: heartFloatSub-4 1s 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: heartFloatSub-4 1s 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  opacity: 0.4;
}

@-webkit-keyframes heartPulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}

@keyframes heartPulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}

@-webkit-keyframes heartUnlike {
  50% {
    transform: scale(0.75);
  }
}

@keyframes heartUnlike {
  50% {
    transform: scale(0.75);
  }
}

@-webkit-keyframes heartFloatMain-1 {
  0% {
    opacity: 0;
    transform: translate(0) rotate(0);
  }

  50% {
    opacity: 1;
    transform: translate(0, -25px) rotate(-20deg);
  }
}

@keyframes heartFloatMain-1 {
  0% {
    opacity: 0;
    transform: translate(0) rotate(0);
  }

  50% {
    opacity: 1;
    transform: translate(0, -25px) rotate(-20deg);
  }
}

@-webkit-keyframes heartFloatMain-2 {
  0% {
    opacity: 0;
    transform: translate(0) rotate(0) scale(0);
  }

  50% {
    opacity: 0.9;
    transform: translate(-10px, -38px) rotate(25deg) scale(1);
  }
}

@keyframes heartFloatMain-2 {
  0% {
    opacity: 0;
    transform: translate(0) rotate(0) scale(0);
  }

  50% {
    opacity: 0.9;
    transform: translate(-10px, -38px) rotate(25deg) scale(1);
  }
}

@-webkit-keyframes heartFloatSub-1 {
  0% {
    visibility: hidden;
    transform: translate(0) rotate(0);
  }

  50% {
    visibility: visible;
    transform: translate(13px, -13px) rotate(30deg);
  }
}

@keyframes heartFloatSub-1 {
  0% {
    visibility: hidden;
    transform: translate(0) rotate(0);
  }

  50% {
    visibility: visible;
    transform: translate(13px, -13px) rotate(30deg);
  }
}

@-webkit-keyframes heartFloatSub-2 {
  0% {
    visibility: hidden;
    transform: translate(0) rotate(0);
  }

  50% {
    visibility: visible;
    transform: translate(18px, -10px) rotate(55deg);
  }
}

@keyframes heartFloatSub-2 {
  0% {
    visibility: hidden;
    transform: translate(0) rotate(0);
  }

  50% {
    visibility: visible;
    transform: translate(18px, -10px) rotate(55deg);
  }
}

@-webkit-keyframes heartFloatSub-3 {
  0% {
    visibility: hidden;
    transform: translate(0) rotate(0);
  }

  50% {
    visibility: visible;
    transform: translate(-10px, -10px) rotate(-40deg);
  }

  100% {
    transform: translate(-50px, 0);
  }
}

@keyframes heartFloatSub-3 {
  0% {
    visibility: hidden;
    transform: translate(0) rotate(0);
  }

  50% {
    visibility: visible;
    transform: translate(-10px, -10px) rotate(-40deg);
  }

  100% {
    transform: translate(-50px, 0);
  }
}

@-webkit-keyframes heartFloatSub-4 {
  0% {
    visibility: hidden;
    transform: translate(0) rotate(0);
  }

  50% {
    visibility: visible;
    transform: translate(2px, -18px) rotate(-25deg);
  }
}

@keyframes heartFloatSub-4 {
  0% {
    visibility: hidden;
    transform: translate(0) rotate(0);
  }

  50% {
    visibility: visible;
    transform: translate(2px, -18px) rotate(-25deg);
  }
}

.react-pdf__Page__annotations.annotationLayer {
  display: none;
}

.topSellingP {
  background: #2e2b2b;
  border: 1px solid rgb(46 43 43 / 80%);
  border-radius: 10px;
  margin-right: 20px;
  height: 100px;
}

/* .react-multi-carousel-item {
  width: 370px !important;
} */

.custom-button-group {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  right: 0;
  gap: 20px;
}

.container-padding-bottom {
  padding-bottom: 20px;
}

.custom-button-group button {
  background: white;
  border: 1px solid #3b3661;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
}

/* .react-multi-carousel-track {
  height: 170px;
} */

/* @media (max-width: 500px) {
  .video-tag {
    height: 14rem;
  }
} */

.DetailBG {
  background: -webkit-linear-gradient(to right, #4568dc, #b06ab3);
  background: linear-gradient(to right, #4568dc, #b06ab3);
  overflow: hidden;
}

.image_type {
  width: 60%;
  height: 460px;
  box-shadow: 0px 0px 12px rgb(62 58 58 / 96%), inset #c1bcbc 0px 0px 8px;
  border-radius: 18px;
  background: #fff;
  z-index: 5;
}

.image_type img {
  object-fit: cover;
  border-top-left-radius: 10px;
  border-radius: 18px;
  border-top-right-radius: 18px;
}

.nft_main {
  position: relative;
  display: flex;
  justify-content: flex-end;
  /* flex-direction: column; */
}

.detailNFT {
  z-index: 1;
  position: absolute;
  right: -150px;
  height: 560px;
  width: 100%;
  top: 2px;
  opacity: 0.3;
  object-fit: contain;
}

.nft_Price_Type,
.nft_property {
  margin-top: 30px;
  width: 60%;
  background: rgb(46 43 43 / 80%);
  border: 1px solid rgb(46 43 43 / 80%);
  border-radius: 10px;
  padding: 10px;
  z-index: 5;
}

.nft_property {
  margin-top: 20px;
}

.nft_Price_Type h4 {
  font-size: 25px;
  color: #fff;
  /* text-shadow: -1px 4px 4px #030f08; */
  font-weight: 500;
  margin-bottom: 0;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nft_property h3 {
  font-size: 18px;
  color: #c1bdbd;
  font-weight: 800;
}

.property_detail p {
  font-size: 12px;
  color: #ebf7ff;
  font-weight: 500;
  margin-bottom: 0;
}

.image_type .main-img {
  position: relative;
  width: 100%;
  height: 100%;
}

.image_bg .card-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

@media (max-width: 780px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .width-25 {
    width: 16% !important;
    height: 45px !important;
  }

  /* .react-multi-carousel-item {
    width: 366px !important;
  } */

  .topSellingP {
    margin-right: 20px;
    margin-left: 20px;
    padding: 0 11px;
  }

  .boldhead.slider {
    width: 31%;
  }

  .boldhead.card_nft {
    width: 32.5%;
  }

  .cardNFTName.h5 {
    font-size: 14px !important;
    margin-top: 30px !important;
  }
}

.nft_Buttons_left {
  gap: 20px;
}

.nft_Buttons_left .likeCount {
  border: 0.5px solid #fff;
  width: 80px;
  box-shadow: 2px 2px 8px rgb(30 28 28);
  padding-right: 12px;
  max-width: 100px;
  background: #000;
}

.nft_Buttons_left .like-button {
  border: 0px solid #fff;
  box-shadow: none;
  padding: 7px;
  /* background: #000; */
}

.likeCount .countLike span {
  color: #fff;
}

/* .nft_Buttons_left .like-icon {
  width: 18px;
  height: 18px;
} */
.verify {
  display: block;
  width: 100px;
  height: 36px;
  border: 0.5px solid #fff;
  border-radius: 22px;
  box-shadow: 2px 2px 8px rgb(30 28 28);
  padding-right: 12px;
  background: #000;
  text-decoration: none;
}

.verify span {
  color: #fff;
  text-decoration: none;
  font-size: 12px;
}

.verify img {
  width: 35px;
  height: 35px;
  padding: 6px;
  border: none;
  border-radius: 100%;
  box-shadow: 0 2px 3px rgb(0 0 0 / 16%);
}

.share {
  width: 36px;
  height: 36px;
  /* padding   : 6px; */
  border: 0.5px solid #fff;
  border-radius: 100%;
  display: block;
  box-shadow: 2px 2px 8px rgb(30 28 28);
  background: #000;
}

.share img {
  width: 50%;
  height: 50%;
}

.bitomp img {
  width: 90%;
  height: 90%;
}

.accordion-item {
  margin-top: 30px;
  /* background-color: #2E2B2B !important; */
  background: rgb(46 43 43 / 80%) !important;

  border: 1px solid rgb(46 43 43 / 80%) !important;
  border-radius: 10px !important;
}

.accordion-button {
  background: transparent !important;
  color: #c1bdbd !important;
  font-weight: 900 !important;
  font-size: 16px !important;
  border-bottom: 1px solid rgba(112, 112, 112, 57%) !important;
  box-shadow: none !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-radius: 10px !important;
}

.accordion-button:not(.collapsed) {
  border-radius: 0px !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(./assets/down.svg) !important;
}

.accordion-button::after {
  background-image: url(./assets/down.svg) !important;
}

.owned,
.mintBy,
.collectible {
  width: 180px;
  min-height: 80px;
  padding: 10px;
  background: #778ae4;
  border-radius: 5px;
}

.owned h4,
.mintBy h4,
.collectible h4 {
  color: #c1bdbd;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
}

.owned p,
.mintBy p,
.collectible p {
  color: #ffffff;
  font-weight: 500;
  /* text-transform: uppercase; */
  font-size: 15px;
}

.owned img,
.mintBy img,
.collectible img {
  box-shadow: 0 0 6px #5e6dd6;
}

.nft_name {
  padding-top: 12px;
  color: #000;
  font-size: 40px;
  font-weight: 900;
  /* text-shadow: 0 3px 6px rgba(0, 0, 0, 70%); */
}

.nft_description {
  font-size: 14px;
  color: #000;
  margin-top: 25px;
}

.nft_detail .row .col span {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

.nft_detail .row .col p,
.nft_detail .row .col a {
  color: #fff;
  text-decoration: none;
  font-size: 12px;
}

.nft_detail .row .col img {
  width: 20px;
  height: 20px;
  margin-left: 0px;
}

.history_detail table {
  color: #fff;
}

.table-hover>tbody>tr:hover>* {
  --bs-table-accent-bg: white !important;
  color: #000 !important;
}

.button_box {
  width: 60%;
  margin-top: 30px;
}

.cancelNft {
  margin: 20px auto;
  width: 100%;
}

@media (max-width: 768px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .width-25 {
    width: 16% !important;
    height: 45px !important;
  }

  .aboutDetail {
    flex-direction: column;
    gap: 20px;
  }

  .owned,
  .mintBy,
  .collectible {
    width: 100%;
    text-align: center;
  }

  .button_box {
    width: 100%;
    margin-top: 30px;
  }

  .nft_Price_Type,
  .nft_property {
    margin-top: 30px;
    width: 100%;
  }

  .image_type {
    width: 90%;
    margin: 0 auto;
  }
}

.logoImg {
  width: auto;
  margin-right: 10px;
  height: 40px;
}

.modal-body .video-tag-common {
  /* max-height: 33.1vh; */
  margin-bottom: 0;
}

.detail_pdf canvas.react-pdf__Page__canvas {
  height: 384px !important;
  margin-bottom: 5px;
}

.pdf-style.detail_pdf .fa-arrow-circle-left {
  width: 50%;
  text-align: end;
  position: relative;
  z-index: 1000;
}

.pdf-style.detail_pdf .fa-arrow-circle-right {
  position: relative;
  z-index: 1000;
}

.pdf-style.detail_pdf p {
  text-align: center;
}

.modal-body .pdf-style.detail_pdf .fa-arrow-circle-left {
  width: auto;
}

.audio-video-tag-common.detailAudio {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  margin-bottom: 14px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
}

.modal-body .audio-video-tag-common {
  width: 100%;
  height: 340px;
  margin-bottom: 0;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  border-radius: 10px;
  background-position: center;
}

.sendNFT {
  background-color: #b00fb5 !important;
  color: #fff !important;
  border: 1px solid #b00fb5 !important;
  width: 100%;
}

.aboutDetail {
  gap: 20px;
}

/* OR Code Animation */

.qrModal .modal-header .btn-close {
  filter: invert();
  opacity: 1;
}

.padding-modal-image {
  height: 470px;
  position: relative;
  background: linear-gradient(to right, white 8px, transparent 8px) 0 0,
    linear-gradient(to right, white 8px, transparent 8px) 0 100%,
    linear-gradient(to left, white 8px, transparent 8px) 100% 0,
    linear-gradient(to left, white 8px, transparent 8px) 100% 100%,
    linear-gradient(to bottom, white 8px, transparent 8px) 0 0,
    linear-gradient(to bottom, white 8px, transparent 8px) 100% 0,
    linear-gradient(to top, white 8px, transparent 8px) 0 100%,
    linear-gradient(to top, white 8px, transparent 8px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 60px 60px;
}

.qrModal .modal-dialog {
  max-width: 530px !important;
}

.qrModal .modal-body {
  padding: 2rem !important;
}

.qrModal .modal-content {
  background: #4568dc !important;
}

.qr-img {
  width: 95%;
  /* height: 100%; */
}

.imgBox {
  height: 400px;
  text-align: center;
  overflow: hidden;
  animation-name: myHeight;
  animation-duration: 3s;
  animation-timing-function: linear;
}

@keyframes myHeight {
  0% {
    height: 0px;
    border-bottom: 6px solid #00d00a;
  }

  25% {
    height: 100px;
    border-bottom: 6px solid #00d00a;
  }

  50% {
    height: 200px;
    border-bottom: 6px solid #00d00a;
  }

  75% {
    height: 300px;
    border-bottom: 6px solid #00d00a;
  }

  95% {
    height: 370px;
    border-bottom: 6px solid #00d00a;
  }

  100% {
    height: 400px;
    border-bottom: 0;
  }
}

.swal2-image {
  margin-bottom: 0 !important;
}


@media (max-width: 768px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .width-25 {
    width: 15% !important;
    height: 45px !important;
  }

  .myNFT .card-body {
    display: none;
  }

  .myNFT .card {
    width: 100px;
    padding: 0 !important;
    overflow: hidden;
  }

  .myNFT canvas.react-pdf__Page__canvas {
    height: 120px !important;
    margin-bottom: 0;
  }

  .myNFT .min-height-200-new,
  .myNFT .audio-video-tag,
  .myNFT .video-tag {
    height: 120px;
  }

  .myNFT .customShado.rounded,
  .myNFT .imgRadius,
  .myNFT .video-player {
    border-radius: 0.7rem !important;
  }

  .myNFT .main-img,
  .myNFT .video-tag,
  .myNFT .audio-video-tag {
    margin-bottom: 0;
  }

  .myNFT .card-img-overlay-custome.likeCount {
    display: none;
  }

  .myNFT .img-box-home,
  .myNFT .corner-ribbon::after {
    display: none;
  }
}

.swalPara {
  font-size: 14.5px;
}

.swallList {
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  padding-left: 15px;
  align-items: center;
}

.swallList li {
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
}

/* .swallList li:first-child::before {
  content: none;
} */

.swallList li::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background: #000;
  border-radius: 100%;
  margin-left: -10px;
  margin-right: 10px;
}

.privacyModal .modal-dialog {
  max-width: 90%;
  margin: 0.5rem auto;
}

.img-box-home {
  bottom: -24px !important;
}

.privacyModal .modal-body {
  max-height: 80vh;
  overflow: auto;
  margin-bottom: 20px;
}

@media (max-width: 912px) {
  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .padding-modal-image {
    height: 450px;
  }

  .imgBox {
    height: 450px;
  }

  @keyframes myHeight {
    0% {
      height: 0px;
      border-bottom: 6px solid #00d00a;
    }

    25% {
      height: 100px;
      border-bottom: 6px solid #00d00a;
    }

    50% {
      height: 200px;
      border-bottom: 6px solid #00d00a;
    }

    75% {
      height: 300px;
      border-bottom: 6px solid #00d00a;
    }

    95% {
      height: 400px;
      border-bottom: 6px solid #00d00a;
    }

    100% {
      height: 450px;
      border-bottom: 0;
    }
  }
}

@media (min-width: 768px) {
  /* .ReactCrop {
    max-height: 216px !important;
  }
 
.defautprofilefull  {
    width: 100%;
    height: auto !important;
  } */

  .padding-modal-image {
    height: 450px;
  }

  .imgBox {
    height: 400px;
  }

  @keyframes myHeight {
    0% {
      height: 0px;
      border-bottom: 6px solid #00d00a;
    }

    25% {
      height: 100px;
      border-bottom: 6px solid #00d00a;
    }

    50% {
      height: 200px;
      border-bottom: 6px solid #00d00a;
    }

    75% {
      height: 300px;
      border-bottom: 6px solid #00d00a;
    }

    95% {
      height: 360px;
      border-bottom: 6px solid #00d00a;
    }

    100% {
      height: 400px;
      border-bottom: 0;
    }
  }
}

@media (max-width: 768px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .img-box-home {
    bottom: -20px !important;
  }

  .privacyModal .modal-dialog {
    max-width: 90%;
    margin: 0.5rem auto;
  }

  .privacyModal .modal-body {
    max-height: 500px;
    overflow: auto;
    margin-bottom: 20px;
  }

  /* 
  .padding-modal-image {
    height: 300px;
  }

  .imgBox {
    height: 250px;
  }

  @keyframes myHeight {
    0% {
      height: 0px;
      border-bottom: 6px solid #00d00a;
    }

    25% {
      height: 50px;
      border-bottom: 6px solid #00d00a;
    }

    50% {
      height: 100px;
      border-bottom: 6px solid #00d00a;
    }

    75% {
      height: 150px;
      border-bottom: 6px solid #00d00a;
    }

    95% {
      height: 200px;
      border-bottom: 6px solid #00d00a;
    }

    100% {
      height: 250px;
      border-bottom: 0;
    }
  } */
}

@media (max-width: 745px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .padding-modal-image {
    height: 450px;
  }

  .imgBox {
    height: 390px;
  }

  @keyframes myHeight {
    0% {
      height: 0px;
      border-bottom: 6px solid #00d00a;
    }

    25% {
      height: 70px;
      border-bottom: 6px solid #00d00a;
    }

    50% {
      height: 160px;
      border-bottom: 6px solid #00d00a;
    }

    75% {
      height: 250px;
      border-bottom: 6px solid #00d00a;
    }

    95% {
      height: 300px;
      border-bottom: 6px solid #00d00a;
    }

    100% {
      height: 390px;
      border-bottom: 0;
    }
  }
}

@media (min-width: 390px) and (max-width: 450px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .width-25 {
    width: 13% !important;
    height: 45px !important;
  }

  .padding-modal-image {
    height: 320px;
  }

  .imgBox {
    height: 260px;
  }

  @keyframes myHeight {
    0% {
      height: 0px;
      border-bottom: 6px solid #00d00a;
    }

    25% {
      height: 50px;
      border-bottom: 6px solid #00d00a;
    }

    50% {
      height: 100px;
      border-bottom: 6px solid #00d00a;
    }

    75% {
      height: 150px;
      border-bottom: 6px solid #00d00a;
    }

    95% {
      height: 200px;
      border-bottom: 6px solid #00d00a;
    }

    100% {
      height: 260px;
      border-bottom: 0;
    }
  }
}

@media (max-width: 375px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .padding-modal-image {
    height: 300px;
  }

  .imgBox {
    height: 250px;
  }

  @keyframes myHeight {
    0% {
      height: 0px;
      border-bottom: 6px solid #00d00a;
    }

    25% {
      height: 50px;
      border-bottom: 6px solid #00d00a;
    }

    50% {
      height: 100px;
      border-bottom: 6px solid #00d00a;
    }

    75% {
      height: 150px;
      border-bottom: 6px solid #00d00a;
    }

    95% {
      height: 200px;
      border-bottom: 6px solid #00d00a;
    }

    100% {
      height: 250px;
      border-bottom: 0;
    }
  }
}

@media (max-width: 360px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .padding-modal-image {
    height: 270px;
  }
}

@media (max-width: 280px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .padding-modal-image {
    height: 194px;
  }

  .imgBox {
    height: 140px;
  }

  @keyframes myHeight {
    0% {
      height: 0px;
      border-bottom: 6px solid #00d00a;
    }

    25% {
      height: 30px;
      border-bottom: 6px solid #00d00a;
    }

    50% {
      height: 70px;
      border-bottom: 6px solid #00d00a;
    }

    75% {
      height: 100px;
      border-bottom: 6px solid #00d00a;
    }

    95% {
      height: 120px;
      border-bottom: 6px solid #00d00a;
    }

    100% {
      height: 140px;
      border-bottom: 0;
    }
  }
}

/* .qrModal {
    top: 20% !important;
  } */

.only-for-mobile {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  color: white;
}

.home-music audio {
  height: 30px;
}

.discBody ul li {
  list-style-type: decimal;
}

.toggleBtn .btn {
  background-color: #fff;
  color: #000;
  border-color: #fff;
}

.toggleBtn .btn-check:checked+.btn-primary {
  background-color: #000;
  color: #fff;
  border-color: #000;
  box-shadow: none;
}

.toggleBtn .btn-check:focus+.btn,
.toggleBtn .btn:focus {
  box-shadow: none;
}

.toggleBtn .btn-check:hover+.btn {
  background-color: #000;
  color: #fff;
}

.nftDetailModal img.min-height-200-new11 {
  width: 100%;
  height: 326px;
  object-fit: cover;
  border-radius: 18px;
}

.nftDetailModal .detail_nft {
  box-shadow: -4px 5px 15px 5px #000000;
}

.modalRow {
  z-index: 200;
}

.discBody ul li {
  list-style-type: decimal;
}

.toggleBtn .btn {
  background-color: #fff;
  color: #000;
  border-color: #fff;
}

.toggleBtn .btn-check:checked+.btn-primary {
  background-color: #000;
  color: #fff;
  border-color: #000;
  box-shadow: none;
}

.toggleBtn .btn-check:focus+.btn,
.toggleBtn .btn:focus {
  box-shadow: none;
}

.toggleBtn .btn-check:hover+.btn {
  background-color: #000;
  color: #fff;
}

.comInSoon {
  background: #e9ecef;
  color: #a16ab9;
  font-size: 10px;
  margin-left: 10px;
  display: flex;
  text-align: center;
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  letter-spacing: 4px;
  padding: 0 5px;
}

.createNewNFT .content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.createNewNFT .content-container .auth-wrapper h3 {
  padding: 0 0 20px 0;
  line-height: 40px;
  letter-spacing: 5px;
}

.pagination {
  display: flex;
  list-style: none;
  padding-left: 0;
  flex-wrap: wrap;
  gap: 2px 0;
}

/* lader css */

.three-body {
  --uib-size: 35px;
  --uib-speed: 0.8s;
  --uib-color: #5d3fd3;
  position: relative;
  display: inline-block;
  height: var(--uib-size);
  width: var(--uib-size);
  animation: spin78236 calc(var(--uib-speed) * 2.5) infinite linear;
  position: absolute;
  top: 50%;
  left: 45%;
}

.three-body__dot {
  position: absolute;
  height: 100%;
  width: 30%;
}

.three-body__dot:after {
  content: "";
  position: absolute;
  height: 0%;
  width: 100%;
  padding-bottom: 100%;
  background-color: var(--uib-color);
  border-radius: 50%;
}

.three-body__dot:nth-child(1) {
  bottom: 5%;
  left: 0;
  transform: rotate(60deg);
  transform-origin: 50% 85%;
}

.three-body__dot:nth-child(1)::after {
  bottom: 0;
  left: 0;
  animation: wobble1 var(--uib-speed) infinite ease-in-out;
  animation-delay: calc(var(--uib-speed) * -0.3);
}

.three-body__dot:nth-child(2) {
  bottom: 5%;
  right: 0;
  transform: rotate(-60deg);
  transform-origin: 50% 85%;
}

.three-body__dot:nth-child(2)::after {
  bottom: 0;
  left: 0;
  animation: wobble1 var(--uib-speed) infinite calc(var(--uib-speed) * -0.15) ease-in-out;
}

.three-body__dot:nth-child(3) {
  bottom: -5%;
  left: 0;
  transform: translateX(116.666%);
}

.three-body__dot:nth-child(3)::after {
  top: 0;
  left: 0;
  animation: wobble2 var(--uib-speed) infinite ease-in-out;
}

@keyframes spin78236 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes wobble1 {

  0%,
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }

  50% {
    transform: translateY(-66%) scale(0.65);
    opacity: 0.8;
  }
}

@keyframes wobble2 {

  0%,
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }

  50% {
    transform: translateY(66%) scale(0.65);
    opacity: 0.8;
  }
}

.myNFT .three-body {
  position: absolute;
  top: 35%;
  left: 35%;
}

.lazyload-wrapper {
  height: 260px;
  position: relative;
  /* margin-bottom: 10px; */
}

.myNFT .lazyload-wrapper {
  height: 120px;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .profile-collection.token-badge {
    right: -68px !important;
  }

  .ReactCrop {
    max-height: 216px !important;
  }

  .defautprofilefull {
    width: 100%;
    height: auto !important;
  }

  .lazySlide .lazyload-wrapper {
    height: 235px;
    position: relative;
    margin-bottom: 10px;
  }
}

td {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* @media (min-width: 1200px){
.modal-xl {
    max-width: 100%;
}
} */
.sendModal td {
  max-width: 100px;
  white-space: break-spaces;
  word-break: break-word;
}

.sendModal .modal-dialog {
  max-width: 60%;
}

.buttonDesktop_None {
  display: none !important;
}

.btnBOX {
  display: flex;
}

@media (max-width: 768px) {
  .sendModal .modal-dialog {
    max-width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .btnBOX {
    position: relative;
    display: block;
  }

  .btnBOX .notification-nav {
    position: absolute;
    top: 0;
    right: 0;
  }

  .buttonMobile_None {
    display: none !important;
  }

  .buttonDesktop_None {
    display: inline-block !important;
  }

  .freenft-table tr td:nth-child(2),
  .freenft-table tr td:nth-child(3) {
    width: 60px;
  }
}

@media screen and (max-width: 500px) {
  .homeNav {
    position: absolute !important;
    right: 51px !important;
    top: 15px !important;
  }

  .shadowcstm .card-body p {
    margin-bottom: 0.6rem !important;
  }


  .body-card {
    padding: 0px 20px 10px 20px !important;
  }

  .myNFT .lazyload-wrapper {
    height: auto !important;
  }

  .lazyload-wrapper {
    height: 250px !important;
  }

  .video-tag,
  .swiper-slide img,
  .min-height-200-new,
  .audio-tag {
    height: 240px !important;
  }

  .swiper-slide .tick-badge img {
    position: relative !important;
    top: 0 !important;
    right: 5px !important;
    z-index: 100 !important;
    width: 29px !important;
    height: 29px !important;
  }


  .tick-badge img {
    position: relative !important;
    top: 0 !important;
    right: 5px !important;
    z-index: 100 !important;
    width: 29px !important;
    height: 29px !important;
  }

  .swiper-slide .token-badge {
    position: relative !important;
    top: 0 !important;
    right: -23px !important;
    z-index: 100 !important;
    width: 15px !important;
  }

  .token-badge {
    position: relative !important;
    top: 0 !important;
    right: -75px !important;
    z-index: 100 !important;
    width: 79px !important;
  }

  .swiper-slide .tick-badge {
    position: relative !important;
    top: 0 !important;
    right: -40px !important;
    z-index: 100 !important;
    width: 120px !important;
  }

  .tick-badge {
    position: relative !important;
    top: 0 !important;
    right: -58px !important;
    z-index: 100 !important;
    width: 120px !important;
  }

  .swiper-slide .token-badge img {
    width: 22px !important;
    height: 22px !important;
  }

  .token-badge img {
    width: 28px !important;
    height: 28px !important;
  }

  .proFile img.inner-ing {
    height: 50px !important;
  }

  canvas.react-pdf__Page__canvas {
    height: 255px !important;
  }

  /* .mynft .min-height-200-new,
  .mynft .audio-video-tag {
    height: 120px !important;
  } */

  .myNFT .min-height-200-new,
  .myNFT .audio-video-tag,
  .myNFT .video-tag {
    height: 120px !important;
  }

  .myNFT .home-music audio {
    height: 20px;
  }
}

.shadowcstm .card-body p {
  margin-bottom: 0.4rem;
}

.declinedSection {
  min-height: calc(100vh - 428px);
}

.arrowdown-img img {
  height: auto !important;
}

.footerSection {
  min-height: calc(100vh - 428px);
}

/*19-07-2023 Css Here*/
.badgeBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.badgeBox .customTokenbadge,
.badgeBox .customTickbadge {
  position: static;
  width: auto;
  height: auto;
}

.badgeBox .customTokenbadge img,
.badgeBox .customTickbadge img {
  width: 25px;
  height: auto !important;
}

.boldhead {
  width: 80px;
  margin-right: 0px;
}

.boldhead::after {
  content: ":";
  position: absolute;
  right: 10px;
}

.cardNFTName {
  /* font-size: 14px !important; */
  margin-bottom: -2px !important;
  margin-top: 12px !important;
}

.check-icon {
  color: green;
  cursor: pointer;
  font-size: 20px !important;
}

.check-disable-icon {
  color: green;
  cursor: no-drop;
  font-size: 20px !important;
}

.times-icon {
  color: red;
  cursor: pointer;
  font-size: 20px !important;
}

.offer-info {
  font-size: 11px;
  position: relative;
  top: 12px;
  left: 12px;
  color: red;
  padding-right: 13px;
}

.tbody-details td {
  font-size: 12px;
  font-weight: 500;
}

.table-class {
  table-layout: fixed;
  overflow: hidden;
}

.offer-cancel-btn {
  text-align: center;
}

.offer-action-th {
  position: relative;
  left: 5.5%;
}

@media screen and (max-width: 780px) {
  .homeNav {
    position: absolute !important;
    right: 51px !important;
    top: 15px !important;
  }

  .boldhead.slider {
    width: 75px;
  }

  .boldhead.card_nft {
    width: 80px;
  }
}

@media screen and (max-width: 500px) {
  .homeNav {
    position: absolute !important;
    right: 51px !important;
    top: 15px !important;
  }


  .cardNFTBYACount,
  .cardNFTBY {
    width: 65%;
    text-align: start;
  }

  th.t-header {
    font-size: 12px;
  }

  td.t-header {
    font-size: 12px;
    word-wrap: break-word;
  }

  button.BuyNFT.btn.btn-danger {
    font-size: 8px;
  }
}

.zerofees {
  margin-left: 48%;
}

@media screen and (min-width: 1200px) and (max-width: 1517px) {
  .logoImg {
    width: auto;
    margin-right: 10px;
    height: 35px;
  }

  .cstmAppNav a.nav-link {
    font-size: 15px;
    /* padding: 0.5rem 0.5rem; */
    padding: 6.5px 10px 0.5px 10px;
  }

  .homeNav {
    margin-right: 15px;
  }

  .cstmAppNav .navbar-collapse .navbar-nav p span {
    font-size: 13px;
  }

  .cstmAppNav .navbar-collapse .searchInput {
    display: inline-block !important;
    width: 15%;
    margin-right: 10px;
  }

  .cstmAppNav .navbar-collapse button.btn {
    font-size: 15px;
  }
}

@media screen and (min-width: 1080px) and (max-width: 1200px) {
  .logoImg {
    width: auto;
    margin-right: 10px;
    height: 30px;
  }

  .cstmAppNav a.nav-link {
    font-size: 14px;
    /* padding: 0.5rem 0.5rem; */
    padding: 6.5px 10px 0.5px 10px;
  }

  .homeNav {
    margin-right: 10px;
  }

  .cstmAppNav .navbar-collapse .navbar-nav p span {
    font-size: 13px;
  }

  .cstmAppNav .navbar-collapse .searchInput {
    display: inline-block !important;
    width: 12%;
    margin-right: 10px;
  }

  .cstmAppNav .navbar-collapse .searchInput input {
    font-size: 14px;
  }

  .cstmAppNav .navbar-collapse button.btn {
    font-size: 13px;
  }
}

@media screen and (min-width: 991px) and (max-width: 1080px) {
  .logoImg {
    width: auto;
    margin-right: 10px;
    height: 30px;
  }

  .cstmAppNav a.nav-link {
    font-size: 12px;
    /* padding: 0.5rem 0.4rem; */
    padding: 6.5px 10px 0.5px 10px;
  }

  .cstmAppNav .navbar-collapse .navbar-nav p span {
    font-size: 12px;
  }

  .cstmAppNav .navbar-collapse .searchInput {
    display: inline-block !important;
    width: 12%;
    margin-right: 10px;
  }

  .cstmAppNav .navbar-collapse .searchInput input {
    font-size: 12px;
  }

  .cstmAppNav .navbar-collapse button.btn {
    font-size: 12px;
  }
}

.cstmShareBTN {
  width: auto;
  border-radius: 50px;
  color: #fff;
  text-decoration: none;
  padding: 0px 8px;
}

.cstmShareBTN p img {
  margin-right: 10px;
}

.cstmShareBTN p span {
  font-size: 12px;
}

.btn-check:focus+.btn,
.btn:focus {
  box-shadow: none !important;
}

.badge {
  display: inline-block;
  padding: 4px 5px 3px 3px !important;
  font-size: 9px !important;
  font-weight: 100 !important;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 1.25rem !important;
  background-color: #e16de3 !important;
}

.btn .badge {
  position: relative;
  top: -14px !important;
  left: -10px !important;
}

span.notifybutton {
  padding: 0px 10px 0px 10px;
  background: #25cff2;
  border: 1px solid #25cff2;
  border-radius: 4px;
  font-size: 13px;
  color: #fff;
}

span.notify-text {
  margin-left: -10px;
}

.select-ids {
  position: relative;
  left: 10px;
}

.align {
  display: initial;
}

@media (max-width: 768px) {
  .infinite-scroll-component__outerdiv {
    padding: 0 !important;
  }

  .btnBOX button {
    position: absolute;
    top: 0;
    right: 5px;
  }

  form.d-flex.searchInput {
    margin-top: 1rem;
  }
}

@media (max-width: 667px) {
  .zerofees {
    margin-left: 16% !important;
  }
}

/* Profile css */

.profileBox .card {
  margin-top: 30px;
  /* background-color   : #2E2B2B !important; */
  background: #fff !important;
  /* border             : 1px solid #778ae4 !important; */
  border-radius: 10px !important;
  width: 100%;
  text-align: center;
  padding: 20px;
  /* box-shadow: -4px 4px 10px #989292, inset #c1bcbc 5px -6px 8px; */
  box-shadow: 0px 0px 0px #989292, inset #c1bcbc 0px 0px 0px;
}

.profileBox .card .card-img-top {
  height: 160px;
  width: 160px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0px auto;
  border-width: 2px;
  border-style: solid;
  border-color: initial;
  /* border-image: linear-gradient(45deg, rgb(0, 143, 104), rgb(250, 224, 66)) 1 / 1 / 0 stretch; */
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #b06ab3, #4568dc) border-box;
  border-radius: 50em;
  border: 4px solid transparent;
  position: relative;
}

.profileBox .card .editImg {
  position: relative;
  display: inline-block;
}

.profileBox .card .editImg i {
  background: #fff;
  border-radius: 100%;
  padding: 5px;
  position: absolute;
  bottom: 12px;
  right: 10px;
  color: #7869c9;
  border: 1.3px solid #7869c9;
  font-size: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profileModal .modal-content {
  background: #4568dc !important;
}

.profileModal .modal-content .modal-body div:nth-child(1) {
  border-color: #fff !important;
}

.profileModal .modal-content .modal-footer .btn-primary {
  color: #fff;
  border-color: #000 !important;
  font-family: givonic !important;
  box-shadow: 0 0 6px #fff !important;
  background-color: #000 !important;
  border-radius: 20px;
}

.socialbox {
  /* padding: 10px; */
  background: #453387;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.socialbox ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  padding: 10px 25px 10px 10px;
  gap: 15px;
}

.socialbox ul li i {
  font-size: 24px;
  color: #fff;
}

.radicalProfile {
  border: 3px solid rgb(46 43 43 / 80%);
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75%;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 5px 0;
}

.radicalProfile h4 {
  margin-bottom: 0;
  /* color     : #fff; */
}

.top-header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 20px;
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.top-header h4 {
  /* color: #fff; */
  font-size: 16px;
  margin-bottom: 0;
}

.mail {
  position: relative;
}

.mail::before {
  content: "";
  width: 10px;
  height: 10px;
  background: red;
  position: absolute;
  right: -3px;
  top: 0px;
  border-radius: 100%;
}

.profileInput,
.validArtist,
.validScore,
.likeScore {
  gap: 20px;
}

.profileInput .form-control {
  width: 50%;
}

.profileInput .form-label {
  width: 10%;
  font-weight: 800;
}

.profileInput .form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
  border: 0;
  background: transparent;
}

.profileInput .btn-primary,
.profileInput .btn-primary:hover {
  color: #000;
  background-color: #fff;
  border: 0;
}

.validArtist .form-label {
  background: #453387;
  color: #fff;
  padding: 10px;
  border-radius: 9px;
  margin-bottom: 0;
  width: 20%;
}

.validArtist p {
  display: flex;
  gap: 30px;
}

.validScore,
.likeScore {
  margin-top: 20px;
}

.validScore .form-label {
  background: transparent;
  border: 1px solid #453387;
  color: #000;
  padding: 10px;
  border-radius: 9px;
  margin-bottom: 0;
  width: 20%;
}

.validScore .progress {
  width: 68%;
}

.validScore .progress-bar {
  background-color: #453387;
}

.likeScore .form-label {
  background: black;
  color: red;
  border-radius: 100%;
  font-size: 30px;
  padding: 5px 12px;
}

.profileBox .card .form-control:focus {
  border-color: #ccc;
  outline: 0;
  box-shadow: none;
}

.collection svg {
  display: inline;
  fill: #fff;
  width: 25px;
  height: 25px;
}

.collection .btn,
.collection .btn:hover,
.collection .btn:focus {
  background: #453387;
  border-color: #453387;
  box-shadow: none;
}

.allNFt {
  margin-top: 20px;
  transition: width 2s, height 4s;

  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.allNFt img {
  width: calc(25% - 15px);
  height: 230px;
  object-fit: cover;
  border-radius: 20px;
}

.form-floating>label {
  font-weight: 800;
}

.socialbox {
  position: relative;
}

.editSocial {
  background: #fff !important;
  border-radius: 100% !important;
  padding: 3px 8px !important;
  position: absolute;
  bottom: 5px;
  right: -13px;
  color: #7869c9 !important;
  border: 1.3px solid #7869c9 !important;
  font-size: 16px;
  box-shadow: 0 0 6px #a8a7a7;
}

.social label.form-label {
  width: 35%;
  margin-bottom: 0;
}

.social .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  border-radius: 10px !important;
}

.social .form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #283034;
  appearance: none;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* .social .form-control::placeholder{
  color: #495057;
} */
.social .modal-header {
  color: #000;
  border-bottom: 1px solid #283034;
}

.social .modal-footer {
  border-top: 1px solid #283034;
}

.social .modal-footer .btn-primary {
  color: #fff;
  background-color: black;
  border-color: black;
  border-radius: 20px;
  box-shadow: 0 0 6px #fff;
}

.cstm_bg-cardadded {
  position: relative;
}

.cstm_bg-cardadded .radicalProfile {
  position: relative;
  border-color: #e86cea;
}

.cstm_bg-cardadded .img_pos-cstndd {
  position: absolute;
  left: -21px;
  width: calc(100% + 42px);
  top: -23px;
  height: 190px;
  background-size: cover !important;
  border-radius: 10px 10px 0 0;
  background-color: black;
}

.cstm_bg-cardadded .img_pos-cstndd .cstm_bg_ad {
  width: 100%;
  height: 190px;
  border-radius: 10px 10px 0 0;
}

.cardheight-dynmic .card {
  height: calc(100% - 30px);
}

.img_pos-cstndd:before {
  content: "";
  background: #00000054;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 190px;
  border-radius: 10px 10px 0 0;
}

.cstm_bg-cardadded .radicalProfile h4 {
  color: white;
}

.img_pos-cstndd i {
  background: #fff;
  border-radius: 100%;
  padding: 5px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #7869c9;
  border: 1.3px solid #7869c9;
  font-size: 20px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Slider not have image end*/
.validScore .form-label.w-50px {
  width: 100%;
  padding: 8px 24px;
  letter-spacing: 2px;
  border-radius: 60px;
}

.validScore .progress-bar {
  background-color: #028a0f;
}

.qr_codeimgwrp {
  position: absolute;
  right: 20px;
  top: 80px;
  width: 200px;
}

.qr_codeimgwrp img {
  width: 100%;
}

.clrchange_box {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 20px;
}

/* Top Header Changes */
.top-header.changeheader-top {
  justify-content: space-between;
}

.top-header.changeheader-top .newdivideradd {
  display: flex;
  align-items: center;
  gap: 20px;
}

.top-header.changeheader-top .radicalProfile {
  width: fit-content;
  padding: 5px 20px;
  margin: 0;
}

.top-header.changeheader-top .radicalProfile h4 {
  font-size: 1rem;
}

.profileBox .cardheight-dynmic .card .editImg {
  padding-top: 54px;
}

.validArtist .form-label {
  border-radius: 50px;
  white-space: nowrap;
  width: fit-content;
}

button.editBio.btn.btn-primary {
  background: white;
  border: 1px solid #453387;
  border-radius: 50%;
  color: #453387;
  position: absolute;
  top: -12px;
  right: -12px;
}

/* Banner Profile */
.bannerimg_profile {
  min-height: 260px;
  background-color: black;
  position: relative;
}

.bannerimg_profile img {
  width: 100%;
  max-height: 260px;
}

.bannerimg_profile i {
  background: #fff;
  border-radius: 100%;
  padding: 5px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #7869c9;
  border: 1.3px solid #7869c9;
  font-size: 20px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputfont12px input {
  font-size: 12px;
}

@media (max-width: 768px) {
  .qr_codeimgwrp {
    width: 70%;
    margin: 0px auto;
    position: static;
    padding-top: 20px;
  }

  .validArtist .form-label {
    width: fit-content !important;
  }

  .validScore .progress {
    width: 100%;
  }

  .cardheight-dynmic .card {
    margin-top: 0;
    height: fit-content;
  }

  .socialbox ul {
    justify-content: space-around;
    padding-right: 30px;
  }

  /* .profileBox .card .editImg i {
    right: 105px;
  } */
}

/*19-10-2023 Css Here*/
.radical_profileBox {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.validArtist .tabButtonBox {
  background: #453387;
  color: #fff;
  padding: 10px;
  border-radius: 50px;
  margin-bottom: 0;
  white-space: nowrap;
  width: fit-content;
  font-size: 0.85rem;
}

.tabBtnBox {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media screen and (min-width: 991px) and (max-width: 1200px) {
  .socialbox ul li i {
    font-size: 16px;
  }

  .socialbox ul li a img {
    width: 20px;
  }

  .top-header.changeheader-top .radicalProfile h4 {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 768px) {
  .homeNav {
    position: absolute !important;
    right: 51px !important;
    top: 15px !important;
  }

  .top-header.changeheader-top {
    flex-direction: column;
  }

  .radical_profileBox .radicalProfile {
    width: 100% !important;
  }

  .top-header.changeheader-top .radicalProfile {
    padding: 5px 10px;
  }

  .top-header.changeheader-top .radicalProfile h4 {
    font-size: 0.8rem;
  }

  .top-header.changeheader-top .newdivideradd {
    gap: 15px;
  }

  .cstmMrgnBTM {
    margin-bottom: 15px;
  }

  .likeScore {
    flex-direction: column;
  }

  .likeScore .clrchange_box {
    padding: 0;
  }
}

@media screen and (max-width: 650px) {
  .homeNav {
    position: absolute !important;
    right: 51px !important;
    top: 15px !important;
  }

  .padding-modal-image {
    height: auto;
    min-height: 230px;
  }

  .padding-modal-image .imgBox {
    height: auto !important;
  }
}

@media screen and (max-width: 500px) {
  .homeNav {
    position: absolute !important;
    right: 51px !important;
    top: 15px !important;
  }

  .radical_profileBox {
    flex-direction: column;
  }

  .validArtist {
    flex-direction: column;
  }

  .tabBtnBox {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  .validArtist .tabButtonBox {
    width: 100%;
  }

  .validScore,
  .validScore .form-label {
    width: 100% !important;
  }
}

@media screen and (max-width: 450px) {
  .homeNav {
    position: absolute !important;
    right: 51px !important;
    top: 15px !important;
  }

  .top-header.changeheader-top .radicalProfile h4 {
    font-size: 0.8rem;
  }
}

/*31-10-2023 Css Here*/
.cstmAboutLink_CardBox p {
  padding-right: 25px;
}

.cstmAboutLink_CardBox i {
  font-size: 24px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.textArea {
  height: 150px !important;
  border: 1px solid #3f51b5 !important;
  border-radius: 10px;
  overflow-y: auto;
}

.top-header.changeheader-top .radicalProfile {
  padding: 10px 20px;
}

.top-header {
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.qr_codeimgwrp {
  top: 94px;
}

.cstmProfileCardBox form input {
  padding: 13px 10px;
}

.cstmmarginTop {
  margin-top: 30px;
}

/*16-11-2023 Css Here*/
.cstmNameIcon {
  font-size: 22px;
}

.bannerimg_profile {
  height: 350px;
  overflow: hidden;
  border: 0.2px solid lightgray;
  border-radius: 9px;
}

/* .bannerimg_profile {
  height: 260px;
  overflow: hidden;
} */

.metadata-cls {
  display: inline-flex;
}

.metadata-cls .circle-icon {
  position: relative !important;
  left: 7px !important;
}

.circle-icon {
  width: 50%;
  line-height: 16px;
  border-radius: 100%;
  text-align: center;
  background: white;
  padding-top: 1px;
  padding-bottom: 0px;
  padding-left: 7px;
  padding-right: 10px;
  font-size: 8px;
}

.img-full {
  width: 200px;
  height: 50px;
}

/* .bannerimg_profile img {
  height: 100%;
  object-fit: fill;
  object-position: center;
  max-height: 500px !important;
} */
.bannerimg_profile img {
  object-fit: cover;
  object-position: center;
  max-height: 360px;
  width: 100%;
}

.main-img {
  height: 100%;
}

.cardNFTBY img {
  width: 25px !important;
  height: 25px !important;
}

.card-img {
  cursor: pointer;
}

#w3review {
  border: 1px solid black;
  border-radius: 5px;
  height: 150px;
}

@media screen and (max-width:768px) {
  .homeNav {
    position: absolute !important;
    right: 51px !important;
    top: 15px !important;
  }

  .main-img {
    height: auto;
  }
}

img.img-circle-view {
  /* width: 28px; */
  height: 30px !important;
  /* position: relative; */
  /* left: 20px; */
  /* top: -11.5px; */
  border: 1px solid #80808033;
  border-radius: 8px;
}

.top-left-view {
  position: relative;
  top: -4px;
  left: 18px;
  display: flex;
}

p.top-left-text {
  font-size: 12px;
  position: relative;
  top: 7px;
  left: 10px;
}

.body-card {
  padding: 13px 20px 10px 20px;
}

p.body-cart-para {
  font-size: 12px;
}

hr.hr-cls {
  margin-top: -2px;
  margin-bottom: 8px;
}

.token-badge img {
  width: 28px;
  height: 28px;
}

.modal.show .modal-dialog {
  transform: none;
}


.initalmodal strong {
  position: relative;
  left: 70%;
}

/* .modal-dialog.custom-modal {
  max-width: 500px !important;
} */

.wallet-body span {
  float: right;
  margin-top: 0px;
}

.md-body {
  margin-top: 45px;
  margin-bottom: 60px;
  padding-left: 17px;
  padding-right: 17px;
}

button.wallet-button.btn.btn-success {
  padding-left: 35px;
  padding-right: 35px;
  border-radius: 30px;
  border: 1px solid #b00fb5 !important;
  background-color: #b00fb5 !important;
  color: #fff !important;
}

.wallet-body {
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #00000040;
  box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.1);
}

.webcls {
  width: 21.5px;
  height: 21.5px;
  margin-left: -3px;
}

.myNFT .top-left-view {
  display: none;
}


span.issuer-cls {
  padding-right: 34%;
}

.loader-class.row {
  padding: 3%;
}

div.body-cart-para {
  position: relative;
  display: flex;
}

.defautprofile {
  width: 100%;
  height: 300px;
}

.defautprofilefull {
  width: 100% !important;
  height: 360px;
}

.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  max-width: 100% !important;
  max-height: 100% !important;
  width: 100% !important;
}

/* .profileBox .card .card-img-top {
  background: black;
  border: 4px solid transparent;
  border-image: linear-gradient(45deg, #000, #000) 1 / 1 / 0 stretch;
  border-radius: 50%;
  border-radius: 50em;
  height: 160px;
  margin: 0 auto;
  object-fit: cover;
  position: relative;
  width: 160px;
} */

@media screen and (orientation: landscape) {
  /* Your CSS rules for landscape mode go here */

  .profileBox .card .card-img-top {
    object-fit: fill !important;
  }
}


@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .profile-cls.tick-badge.customTickbadge {
    width: 70px !important;
  }
}

.fill-width {
  width: 100%;
}

.profile-nft img.card-img-top.min-height-200-new.imgRadius.mb-md-3 {
  height: 260px !important;
  width: 100% !important;
  object-fit: cover !important;
  border: none !important;
  border-radius: inherit !important;
  margin: 0 !important;
}

.profile-collection.token-badge {
  right: -100px;
}

.minted.p-3.customShado.mb-3.mb-lg-5.mb-md-5.bg-white.rounded.shadowcstm.corner-ribbon.profile-ribbon.card::after {
  right: 29px !important;
}

button#dropdown-basic {
  position: relative;
  right: 14px;
  background: transparent;
  color: black;
  border: none;
}

a.dropdown-item a {
  text-decoration: none;
  color: #000;
}

a.dropdown-item a:hover {
  text-decoration: none !important;
  color: #fff !important;
  background-color: #b00fb5 !important;
}

.dropdown-item:active,
.dropdown-item:hover {
  color: #fff !important;
  text-decoration: none !important;
  background-color: #b00fb5 !important;
}

.dropdown-item:hover>a {
  color: #fff !important;
}

.dropdown-item:active>a {
  color: #fff !important;
}

.img_preview img {
  height: 120px !important;
  margin-top: 4px !important;
  object-fit: fill;
}

.progress {
  margin-bottom: 4px !important;
}

.main-img.img_preview .video-player {
  position: relative !important;
  top: 5px !important;
  height: 100px !important;
  border-radius: 14px;
}

a.home-class {
  color: #5d5d5b !important;
  padding: .5rem 3rem !important;
  text-decoration: none !important;
  font-weight: 700 !important;
}

.vscore-class {
  margin-top: 5px;
}

.search-wrapper {
  margin-top: 20% !important;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: -60px !important;
  margin-top: .125rem;
}

article.content-editable {
  border: 1px solid #5400b8;
  height: 150px;
  border-radius: 10px;
  background: #e9ebef;
  text-align: left;
  padding-left: 20px;
  overflow-x: hidden;
}

.img-circle-view .img {
  border: 1px solid #5400b8;
}

.head-cls {
  padding-top: 10px;
}

.nft-collections {
  margin-top: 1.5%;
  /* margin-bottom: 1%; */
}

.onwfilea.profile-nft-collections .main-img {
  border-radius: 10px;
}

.profile-nft-collections video.video-player {
  border-radius: 10px !important;
}

.collection-heading {
  text-align: center;
  margin-top: 15px;
  font-weight: bold;
}

button.profile-cls.btn.btn-primary {
  position: relative;
  z-index: 200;
  top: 25px;
  left: 92%;
  border-radius: 50%;
  background: white;
  color: #862acb;
  font-size: 14px;
}

.prof-collect .react-multi-carousel-track {
  height: 100% !important;
}

ul.react-multi-carousel-track li {
  padding-left: 15px;
  padding-right: 35px;
  /* width: 100% !important; */
}

.profile-cls.tick-badge.customTickbadge {
  width: 75px;
}

img.img-profile {
  border: 1px solid #5400b8;
  width: 18px;
  height: 18px !important;
  border-radius: 50%;
}

span.sno-cls {
  font-size: 17px;
  position: relative;
  top: 2px;
}

.default-currencies {
  margin-left: 5px;
}

.spinner-cls {
  padding-top: 20%;
  padding-bottom: 20%;
}


/* .carousel-class ul.react-multi-carousel-track li{
 padding-left: 0 !important;
 padding-right: 0 !important;
} */

/* .setIsActiveWalletcarousel-class button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  bottom: calc(0% + 0px);
  right: calc(5% + 1px);
}
.carousel-class button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  bottom: calc(0% + 0px);
  left: calc(86% + 1px);
} */

.carousel-class button.react-multiple-carousel__arrow--right {
  margin-top: -40px !important;
}

.carousel-class button.react-multiple-carousel__arrow--left {
  margin-top: -40px !important;
}

.vscore-class {
  margin-bottom: 5px !important;
}

.create-nft {
  cursor: pointer !important;
}

.tabs-img {
  cursor: pointer;
}


/* For Tablets or iPad */
/* @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .cstmNameIcon {
    font-size: 22px !important;
    margin-left: -10px !important;
  }

  .profileInput svg.d-block {
    position: relative !important;
    left: -16px !important;
  }

  .qr_codeimgwrp {
    position: absolute !important;
    right: 20px !important;
    width: 200px !important;
  }
} */


/* ipad pro media query */

@media only screen and (min-width: 1194px) and (orientation: landscape) {
  .qr_codeimgwrp {
    position: absolute !important;
    right: 20px !important;
    width: 180px !important;
  }

  .bannerimg_profile {
    min-height: 100% !important;
    height: 100% !important;
    border: 0.2px solid lightgray !important;
    border-radius: 9px !important;
  }
}


@media only screen and (min-width: 834px) and (orientation: portrait) {
  .qr_codeimgwrp {
    position: absolute !important;
    right: 20px !important;
    width: 180px !important;
  }

  .bannerimg_profile {
    min-height: 100% !important;
    height: 100% !important;
    border: 0.2px solid lightgray !important;
    border-radius: 9px !important;
  }
}

/* ipad Air */
/* Portrait */
@media only screen and (min-width: 820px) and (orientation: portrait) {
  .qr_codeimgwrp {
    position: absolute !important;
    right: 20px !important;
    width: 180px !important;
  }

  .bannerimg_profile {
    min-height: 100% !important;
    height: 100% !important;
    border: 0.2px solid lightgray !important;
    border-radius: 9px !important;
  }

}

/* Landscape */
@media only screen and (min-width: 1180px) and (orientation: landscape) {
  .qr_codeimgwrp {
    position: absolute !important;
    right: 11px !important;
    width: 155px !important;
  }

  .bannerimg_profile {
    min-height: 100% !important;
    height: 100% !important;
    border: 0.2px solid lightgray !important;
    border-radius: 9px !important;
  }
}

/* ipad Mini Portrait */
@media only screen and (min-width: 768px) and (orientation: landscape) {
  .qr_codeimgwrp {
    position: absolute !important;
    right: 11px !important;
    width: 155px !important;
  }

  .bannerimg_profile {
    min-height: 100% !important;
    height: 100% !important;
    border: 0.2px solid lightgray !important;
    border-radius: 9px !important;
  }
}

/* ipad Mini Landscape */
@media only screen and (min-height: 1024px) and (orientation: portrait) {
  .qr_codeimgwrp {
    position: absolute !important;
    right: 11px !important;
    width: 155px !important;
  }

  .bannerimg_profile {
    min-height: 100% !important;
    height: 100% !important;
    border: 0.2px solid lightgray !important;
    border-radius: 9px !important;
  }
}

.img-dashboard {
  border: 1px solid lightgray;
  border-radius: 50% !important;
}

a {
  text-decoration: none !important;
}

.token-width img {
  height: 23px !important;
  width: 23px !important;
}

.token-tick img {
  height: 27px !important;
  width: 27px !important;
}

.vertical-buttons {
  flex-direction: column;
}

.confirm-btn {
  margin-top: -1em !important;
  padding-left: 34px !important;
  padding-right: 34px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  border-radius: 30px !important;
  border: 1px solid #b00fb5 !important;
  color: #fff !important;
}

.cancel-btn {
  margin-top: .5em !important;
  margin-bottom: 2em !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  border-radius: 30px !important;
  border: 1px solid #b00fb5 !important;
  color: #fff !important;
}

.swal-container.swal2-center>.swal2-popup{
  /* background-color: #121212 !important; */
  /* background-image: url("/src/assets/registration.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important; */
  border: 10px solid #5400b8 !important;
  border-radius: 15px !important;
  width: 27em !important;
}

/* .swal-container {
  background-image: url("/src/assets/registration.jpg") !important;
} */

button.btn-offer-disabled.btn.btn-success {
  padding: 2px 10px 2px 10px;
  font-size: 13px;
}

.radicalProfile.share-icn {
  cursor: pointer;
}

.tick-badge img {
    width: 35px;
    height: 34px;
}

.newdivideradd img {
  width: 35px;
  height: 35px;
}

.newdivideradd img {
  width: 33px;
  height: 33px;
}

.buyer-img {
  height: 23px;
  border: 1.5px solid #c000ff;
  border-radius: 50%;
  position: relative;
  bottom: 2px;
}


.lazyload-wrapper.lazy-nft {
  height: 100% !important;
}

.clock-class{
  padding: 0px 50px 65px 0px;
}